// @flow
import * as React from "react";
import { DeleteButton, Button } from "../../../components/buttons";
import styled from "styled-components";
import { remove } from "../../../minus";

const Row = styled.div`
  display: flex;
  align-items: center;
  & > div:first-child {
    overflow: hidden;
    margin-right: 10px;
  }
  & > svg {
    flex: 0 0 auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type P<T> = {
  items: T[],
  onChange: (T[]) => void,
  renderItem: (T, number) => React.Node,
  makeItem: () => T
};

const List = <T>(props: P<T>) => {
  const { items, onChange, renderItem, makeItem } = props;
  const del = i => {
    onChange(remove(items, i));
  };
  const add = () => {
    onChange(items.concat(makeItem()));
  };
  return (
    <>
      <Container>
        {items.map((item, i) => (
          <Row key={i}>
            <div>{renderItem(item, i)}</div>
            <DeleteButton onClick={() => del(i)} />
          </Row>
        ))}
      </Container>
      <Button onClick={add}>Add</Button>
    </>
  );
};

export default List;
