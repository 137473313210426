// @flow
import React, { useState } from "react";
import { Button, DeleteButton } from "../../../../components/buttons";
import styled from "styled-components";
import { spacing } from "../../../../components/layout";
import type { Photo as PhotoType } from "../../../../services/api/types";
import PhotoTemplateEditor from "../../PhotoTemplates/PhotoTemplateEditor";
import ShowHideInput from "../ShowHideInput/ShowHideInput";
import ShowtimesInput from "./ShowtimesInput";
import TilePhotoEditorPhoto from "./TilePhotoEditorPhoto";
import { FieldRow } from "../../../../components/forms";

const Container = styled.div`
  display: flex;
  & svg {
    margin-left: ${spacing};
  }
`;

const FormContainer = styled.div`
  flex: 1;
  & label {
    display: block;
  }
`;

type P = {
  photo: PhotoType,
  haveCommonTitle: boolean,
  haveCommonCaption: boolean,
  onChange: any => void,
  onDelete: void => void
};

const TilePhotoEditor = (props: P) => {
  const {
    photo,
    haveCommonTitle,
    haveCommonCaption,
    onChange,
    onDelete
  } = props;
  const [templateOpen, setTemplateOpen] = useState(false);

  const openTemplate = () => setTemplateOpen(true);
  const closeTemplate = () => setTemplateOpen(false);

  const time_data = photo.time_data;
  const showHide = {
    show: time_data.show,
    hide: time_data.hide
  };
  const showTimes = {
    default_showtimes: time_data.default_showtimes,
    showtimes: time_data.showtimes
  };

  return (
    <Container>
      <TilePhotoEditorPhoto photo={photo} onChange={onChange} />
      <FormContainer>
        {!haveCommonTitle && (
          <FieldRow>
            <label>Title</label>
            <input
              value={photo.title}
              onChange={e => {
                onChange({ title: e.target.value });
              }}
            />
          </FieldRow>
        )}

        {!haveCommonCaption && (
          <FieldRow>
            <label>Caption</label>
            <textarea
              rows="3"
              value={photo.comment}
              onChange={e => {
                onChange({ comment: e.target.value });
              }}
            />
          </FieldRow>
        )}

        <FieldRow>
          <label>Show/Hide</label>
          <ShowHideInput
            value={showHide}
            onChange={newShowHide => {
              onChange({
                time_data: {
                  ...time_data,
                  show: newShowHide.show,
                  hide: newShowHide.hide
                }
              });
            }}
          />
        </FieldRow>
        <FieldRow>
          <label>Showtimes</label>
          <ShowtimesInput
            value={showTimes}
            onChange={newShowtimes =>
              onChange({
                time_data: {
                  ...time_data,
                  default_showtimes: newShowtimes.default_showtimes,
                  showtimes: newShowtimes.showtimes
                }
              })
            }
          />
        </FieldRow>
      </FormContainer>

      <div>
        <Button disabled={!photo.id} onClick={openTemplate}>
          Make Template
        </Button>
      </div>

      <DeleteButton confirm="Delete this photo?" onClick={onDelete} />

      {photo.id && templateOpen && (
        <PhotoTemplateEditor
          initialTemplate={{
            title: photo.title,
            comment: photo.comment,
            image_photo_id: photo.id
          }}
          isOpen
          onClose={closeTemplate}
          photo={photo}
        />
      )}
    </Container>
  );
};

export default TilePhotoEditor;
