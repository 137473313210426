// @flow
import React from "react";
import ImageFileInput from "../../../../components/ImageFileInput";
import type { Photo as PhotoType } from "../../../../services/api/types";
import Photo from "../../../../components/Photo";
import PhotoTemplateInput from "../../PhotoTemplates/PhotoTemplateInput";

type P = {
  photo: PhotoType,
  onChange: PhotoType => any
};

const TilePhotoEditorPhoto = (props: P) => {
  const { photo, onChange } = props;
  return (
    <div>
      {photo.photo_url && !photo.file && (
        <Photo
          url={photo.photo_url}
          thumbnail_url={photo.thumbnail_url || photo.photo_url}
        />
      )}
      <ImageFileInput
        value={photo.file || ""}
        onChange={e => onChange({ ...photo, file: e.target.files[0] })}
      />
      {!photo.photo_url && (
        <PhotoTemplateInput
          onChange={template => {
            onChange({
              ...photo,
              photo_template_id: template.id,
              title: template.title,
              comment: template.comment
            });
          }}
        />
      )}
    </div>
  );
};

export default TilePhotoEditorPhoto;
