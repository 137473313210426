// @flow
import React, { useState, useEffect } from "react";
import { Button, DeleteButton } from "../../../components/buttons";
import type { PhotoTemplate } from "../../../services/api/types";
import photoTemplates from "../../../services/api/photo-templates";
import Photo from "../../../components/Photo";
import styled from "styled-components";
import toasts from "../../../services/toasts";

const Container = styled.div`
  overflow-y: scroll;
  max-height: 80vh;
`;

type P = {
  onSelect: PhotoTemplate => any
};

const PhotoTemplatePicker = (props: P) => {
  const { onSelect } = props;

  const [templates, setTemplates] = useState(null);
  const load = async () => {
    setTemplates(await photoTemplates.list());
  };
  const deleteTemplate = async template => {
    if (!templates) return;
    setTemplates(templates.filter(t => t.id !== template.id));
    try {
      await photoTemplates.del(template);
    } catch (err) {
      toasts.error(err.toString());
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (templates === null) {
    return "Loading";
  }

  return (
    <Container>
      <table>
        <tbody>
          {templates.map((template: PhotoTemplate) => (
            <tr key={template.id}>
              <td>
                <Button onClick={() => onSelect(template)}>Select</Button>
              </td>
              <td>
                {template.photo_url && template.thumbnail_url && (
                  <Photo
                    url={template.photo_url}
                    thumbnail_url={template.thumbnail_url}
                  />
                )}
              </td>
              <td>{template.title}</td>
              <td>{template.comment}</td>
              <td>
                <DeleteButton
                  confirm="Delete this template?"
                  onClick={() => deleteTemplate(template)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default PhotoTemplatePicker;
