import React from "react";

export default ({ onChange, value, categories }) => {
  return (
    <select
      required
      value={value || ""}
      onChange={e => onChange(parseInt(e.target.value, 10))}
    >
      <option value="" />
      {categories.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </select>
  );
};
