// @flow
import React, { Component } from "react";
import Async from "../../components/Async";
import api from "../../services/api";
import styled from "styled-components";
import { Button, EditButton } from "../../components/buttons";
import List from "../../components/List";
import toasts from "../../services/toasts";
import EditDialog from "./EditDialog";
import SetAllCount from "./SetAllCount";

const Item = styled.div`
  display: flex;
  justify-content: space-between;
`;

type P = {};
type S = {
  showCreateDialog: boolean
};

const Options = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;

class ReferrerUsers extends Component<P, S> {
  state = {
    showSetCountDialog: false,
    showEditItem: false,
    editableItem: null
  };

  listing = React.createRef<Async>();

  editItem = async (p: any) => {
    try {
      await api.referrer_users.update(p);
      if (this.listing.current) {
        await this.listing.current.refresh();
      }
      this.setState({ showEditDialog: false });
    } catch (err) {
      const message = err.response.data.error_code ? err.response.data.message : err.toString();
      toasts.error(message);
    }
  };

  updateAll = async (p: any) => {
    this.setState({ loading: true, error: false, fetchedProps: [] }, async () => {
      try {
        const fetchedProps = await api.referrer_users.update_all(p);
        await this.listing.current.refresh();
        this.setState({ fetchedProps: fetchedProps, loading: false, showSetCountDialog: false });
      } catch (err) {
        const message = err.response.data.error_code ? err.response.data.message : err.toString();
        toasts.error(message);
        this.setState({ error: true, loading: false });
      }
    });
  };

  showEditWindow = async (item_id: any) => {
    const data = await api.referrer_users.list();
    const itemData = data.find(item => item.id === item_id);

    if (!itemData) {
      toasts.error('Referrer user not found in system');
    }

    this.setState({ showEditDialog: true, editableItem: itemData });
  };

  render() {
    return (
      <div style={{ padding: 30 }}>
        <h3>Referrer users</h3>
        <Options>
          <Button onClick={() => this.setState({ showSetCountDialog: true })}>
            Set All invite count
          </Button>
        </Options>
        <Async fetchProps={api.referrer_users.list} ref={this.listing}>
          {codes => (
            <List>
              {codes.map(({ name, invite_count, id }) => (
                <Item key={ `${name}${invite_count}` }>
                  { name }
                  <span>{ invite_count }</span>
                  <span>
                    <EditButton
                      onClick={() => this.showEditWindow(id)}
                    />
                  </span>
                </Item>
              ))}
            </List>
          )}
        </Async>
        { this.state.showSetCountDialog && (
          <SetAllCount
            onClose={() => this.setState({ showSetCountDialog: false })}
            onSubmit={this.updateAll}
           />
        )}
        { this.state.showEditDialog && (
          <EditDialog
            itemData={this.state.editableItem}
            onClose={() => this.setState({ showEditDialog: false })}
            onSubmit={this.editItem}
          />
        )}
      </div>
    );
  }
}

export default ReferrerUsers;
