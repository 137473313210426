import React from "react";
import { FieldRow } from "components/forms";
import View from "./View";
import api from "services/api";
import history from "services/history";
import styled from "styled-components";
import { connect } from "react-redux";

const Error = styled.div`
  color: red;
  font-weight: bold;
`;

class Signup extends React.Component {
  state = {
    invitation_token: null,
    password: "",
    confirmation: "",
    error: null
  };

  componentDidMount = () => {
    var urlParams = new URLSearchParams(window.location.search);
    this.setState({ invitation_token: urlParams.get("invitation_token") });
  };

  signup = async () => {
    const { password, confirmation, invitation_token } = this.state;
    if (password.length < 6) {
      this.setState({ error: "Password must be at least 6 characters long." });
    } else if (password !== confirmation) {
      this.setState({ error: "Password and confirmation do not match." });
    } else {
      try {
        const data = await api.auth.signup({ password, invitation_token });
        this.props.login(data);
        history.push("/");
      } catch (e) {
        this.setState({ error: "Signup error" });
      }
    }
  };
  render() {
    return (
      <View onSubmit={this.signup} title="Signup">
        <FieldRow>
          <div>Password</div>
          <input
            type="password"
            required
            value={this.state.password}
            onChange={e => this.setState({ password: e.target.value })}
          />
        </FieldRow>
        <FieldRow>
          <div>Confirm password</div>
          <input
            type="password"
            required
            value={this.state.confirmation}
            onChange={e => this.setState({ confirmation: e.target.value })}
          />
        </FieldRow>
        {this.state.error && <Error>{this.state.error}</Error>}
      </View>
    );
  }
}
const dispatcher = dispatch => ({
  login: data => dispatch({ type: "LOGIN", data })
});

export default connect(
  null,
  dispatcher
)(Signup);
