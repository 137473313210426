// @flow
import React from "react";
import styled from "styled-components";
import TimePeriodInput from "./TimePeriodInput";
import { DeleteButton, Button } from "../../../components/buttons";
import type { Period } from "../../../services/api/types";

const Container = styled.div`
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

type P = {
  onChange: (Period[]) => void,
  values: Period[]
};

const WorkingHours = (props: P) => {
  const { onChange, values } = props;

  const addRow = () => {
    onChange([...values, { start: "00:00:00", end: "23:59:00" }]);
  };

  const removeRow = i => {
    onChange([...values.slice(0, i), ...values.slice(i + 1)]);
  };

  const handleChange = (i, val) => {
    onChange([...values.slice(0, i), val, ...values.slice(i + 1)]);
  };

  return (
    <Container>
      {values.map((time, i) => {
        return (
          <Row key={i}>
            <TimePeriodInput
              value={time}
              onChange={val => handleChange(i, val)}
            />
            <DeleteButton onClick={() => removeRow(i)} />
          </Row>
        );
      })}

      <Button type="button" onClick={addRow}>
        Add another row
      </Button>
    </Container>
  );
};

export default WorkingHours;
