import styled from "styled-components";

export const spacing = "15px";
export const borderColor = "rgba(0,0,0,0.05)";

export const Flex = styled.div`
  display: flex;
`;

export const Centered = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  & td {
    border: 1px solid ${borderColor};
    padding: calc(${spacing} / 2);
  }
`;

export const FormButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: ${spacing};
`;
