// @flow
import React, { useState } from "react";
import styled from "styled-components";
import type { PhotoTemplate } from "../../../services/api/types";
import FormDialog from "../../../components/FormDialog";
import PhotoTemplatePicker from "../PhotoTemplates/PhotoTemplatePicker";

const Container = styled.div`
  display: flex;
  & > * {
    flex: 0 0 auto;
  }
`;

type P = {
  onChange: PhotoTemplate => any
};

const PhotoTemplateInput = (props: P) => {
  const { onChange } = props;

  const [open, setOpen] = useState(false);
  const [template, setTemplate] = useState<PhotoTemplate | null>(null);

  const openPicker = () => setOpen(true);
  const closePicker = () => setOpen(false);

  return (
    <>
      <Container>
        <button type="button" onClick={openPicker}>
          Choose Template
        </button>{" "}
        <span>
          {template && template.id
            ? `template #${template.id}`
            : "No template chosen"}
        </span>
      </Container>

      <FormDialog
        title="Choose Tile Photo Template"
        isOpen={open}
        onClose={closePicker}
      >
        <PhotoTemplatePicker
          onSelect={template => {
            closePicker();
            setTemplate(template);
            onChange(template);
          }}
        />
      </FormDialog>
    </>
  );
};

export default PhotoTemplateInput;
