// @flow
import React from "react";
import styled from "styled-components";
import TimeInput from "../TimeInput";
import type { Period } from "../../../services/api/types";

const Row = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin: 0 8px;
  }
`;

type P = {
  value: Period,
  onChange: Period => void
};

const TimePeriodInput = (props: P) => {
  const { value, onChange } = props;
  const { start, end } = value;
  const change = diff => onChange({ ...value, ...diff });
  return (
    <Row>
      <label>From</label>
      <TimeInput value={start} onChange={start => change({ start })} />
      <label>To</label>
      <TimeInput value={end} onChange={end => change({ end })} />
    </Row>
  );
};

export default TimePeriodInput;
