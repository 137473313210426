// @flow
import React, { Component } from "react";
import Activities from "../../components/Activities";
import CollapsibleSection from "../../components/CollapsibleSection";
import { DeleteButton } from "../../components/buttons";
import type { Category as CategoryType } from "../../services/api/types";

type S = {
  open: boolean
};
type P = {
  onDelete: void => void,
  category: CategoryType,
  activities: any,
  onDefaultActivitiesChange: void => void
};

class Category extends Component<P, S> {
  delete = () => {
    !this.props.category.count && this.props.onDelete();
  };
  render() {
    const { category, activities, onDefaultActivitiesChange } = this.props;
    const { name, count, default_activity_ids } = category;
    return (
      <CollapsibleSection
        title={
          <React.Fragment>
            <DeleteButton
              confirm={`Delete category ${name}?`}
              onClick={this.delete}
              disabled={!!count}
            />{" "}
            {name} ({count} places)
          </React.Fragment>
        }
        content={
          <React.Fragment>
            <h5>Default activities</h5>
            <Activities
              activities={activities}
              value={default_activity_ids}
              onChange={onDefaultActivitiesChange}
            />
          </React.Fragment>
        }
      />
    );
  }
}

export default Category;
