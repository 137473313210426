import React from "react";
import styled, { css } from "styled-components";
import colors from "styles/colors";
import { FaTrash, FaEdit } from "react-icons/fa";
import { Dialog } from "@blueprintjs/core";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 3px;
  padding: 6px 15px;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  }
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
  &:disabled {
    cursor: default;
    color: #bbb;
    opacity: 0.5;
  }
`;

export const ColoredButton = styled(Button)`
  color: white;
  background-color: ${colors.playgroundGreen};
`;

export const DangerButton = styled(Button)`
  color: white;
  background-color: ${colors.danger};
`;

export const TextButton = styled.button`
  font: inherit;
  color: inherit;
  background: none;
  border: none;
  display: inline;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
`;

const DeleteIcon = styled(FaTrash)`
  color: grey;
  margin-right: 8px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  ${p =>
    p.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        opacity: 0.5;
      }
    `}
`;

const EditIcon = styled(FaEdit)`
  color: grey;
  margin-right: 8px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export class DeleteButton extends React.Component {
  state = {
    ask: false
  };

  handleClick = e => {
    e.stopPropagation();
    if (this.props.disabled) {
      return;
    }
    if (this.props.confirm) {
      this.setState({ ask: true });
    } else {
      this.props.onClick(e);
    }
  };

  confirm = e => {
    e.stopPropagation();
    this.setState({ ask: false });
    this.props.onClick();
  };

  cancel = e => {
    e.stopPropagation();
    this.setState({ ask: false });
  };

  render() {
    const { confirm, disabled } = this.props;
    const { ask } = this.state;

    return (
      <React.Fragment>
        <DeleteIcon onClick={this.handleClick} disabled={disabled} />
        <Dialog isOpen={ask} onClose={this.cancel}>
          <div className="bp3-dialog-body">{confirm}</div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button onClick={this.confirm}>Yes</Button>
              <Button onClick={this.cancel}>No</Button>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export class EditButton extends React.Component {
  handleClick = e => {
    e.stopPropagation();
    this.props.onClick(e);
  };

  render() {
    return (
      <React.Fragment>
        <EditIcon onClick={this.handleClick}  />
      </React.Fragment>
    );
  }
}
