// @flow
import React from "react";
import { ColoredButton, Button } from "../../../components/buttons";
import { FormButtonsContainer } from "../../../components/layout";
import toasts from "../../../services/toasts";
import { TileTypes } from "../../../services/api/tiles";
import type { Tile as TileType } from "../../../services/api/types";
import Tile from "./Tile";
import SortableList from "./SortableList";
import { swap, replace } from "../../../minus";
import { syncPlaceTiles } from "../../../services/api/tiles-sync";
import tilesAPI from "../../../services/api/tiles";

type P = {
  place_id: number
};
type S = {
  editedTiles: TileType[],
  originalTiles: TileType[],
  loading: boolean
};

class TilesEditor extends React.Component<P, S> {
  state = {
    originalTiles: [],
    editedTiles: [],
    loading: false
  };

  async reload() {
    const { place_id } = this.props;
    this.setState({ loading: true });
    const tiles = await tilesAPI.list(place_id);
    this.setState({
      originalTiles: tiles,
      editedTiles: tiles,
      loading: false
    });
  }

  componentDidMount() {
    this.reload();
  }

  handleChange = (i: number, tile: TileType) => {
    const { editedTiles } = this.state;
    this.setState({
      editedTiles: replace(editedTiles, i, tile)
    });
  };

  handleSave = () => {
    const { place_id } = this.props;
    const { originalTiles, editedTiles } = this.state;

    this.setState({ loading: true }, async () => {
      try {
        await syncPlaceTiles(place_id, originalTiles, editedTiles);
        toasts.success("Saved");
      } catch (err) {
        toasts.error(err.toString());
      }
      await this.reload();
      this.setState({ loading: false });
    });
  };

  handleAddClick = () => {
    const { editedTiles } = this.state;
    const newTile: TileType = {
      order: 0,
      type: TileTypes.CommonNothing,
      photos: [],
      common_title: "",
      common_comment: "",
      time_data: { show: [], hide: [] },
      auto_swipe: true
    };
    // Set the new tile's order as zero, but in state put it at the end
    // of the list. After saving the list will refresh.
    this.setState({
      editedTiles: editedTiles
        .map(x => ({ ...x, order: x.order + 1 }))
        .concat([newTile])
    });
  };

  handleDelete = (i: number) => {
    const { editedTiles } = this.state;
    this.setState({
      editedTiles: [...editedTiles.slice(0, i), ...editedTiles.slice(i + 1)]
    });
  };

  handleSwap = (i: number, j: number) => {
    const { editedTiles } = this.state;
    const swapped = swap(editedTiles, i, j).map((t, i) => ({ ...t, order: i }));
    this.setState({ editedTiles: swapped });
  };

  render() {
    const { editedTiles, loading } = this.state;

    const orderViolated = () => {
      for (let i = 1; i < editedTiles.length; i++) {
        if (editedTiles[i].order <= editedTiles[i - 1].order) {
          return true;
        }
      }
      return false;
    };

    return (
      <div>
        {editedTiles.length === 0 && <p>There are no tiles.</p>}
        <SortableList
          id="sortable-tiles"
          onSwap={this.handleSwap}
          disabled={orderViolated()}
        >
          {editedTiles.map((tile, i) => (
            <Tile
              key={i}
              tile={tile}
              onDelete={() => {
                this.handleDelete(i);
              }}
              onChange={tile => this.handleChange(i, tile)}
            />
          ))}
        </SortableList>
        <Button onClick={this.handleAddClick}>Add Tile</Button>
        <FormButtonsContainer>
          <ColoredButton
            disabled={loading}
            type="button"
            onClick={this.handleSave}
          >
            Save
          </ColoredButton>
        </FormButtonsContainer>
      </div>
    );
  }
}

export default TilesEditor;
