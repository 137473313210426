import React, { useState, useEffect } from "react";
import { FieldRow } from "../../components/forms";
import api from "../../services/api";
import { ColoredButton } from "../../components/buttons";
import { FormButtonsContainer } from "../../components/layout";
import { Loading } from "../../components/Async";
import toasts from "services/toasts";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
`;

const AppSettings = () => {
  const [all_regions, set_all_regions] = useState(false);
  const [quarantine_mode, set_quarantine_mode] = useState(false);
  const [quarantine_title, set_quarantine_title] = useState("");
  const [quarantine_msg, set_quarantine_msg] = useState("");
  const [loading, set_loading] = useState(false);
  const [error, set_error] = useState(false);
  const getSettings = async () => {
    try {
      const settings = await api.app_settings.list();
      set_all_regions(settings.all_regions);
      set_quarantine_mode(settings.quarantine_mode);
      set_quarantine_title(settings.quarantine_title);
      set_quarantine_msg(settings.quarantine_msg);
    } catch (e) {
      set_error(true);
      toasts.error(e.toString());
    }
    set_loading(false);
  };
  useEffect(() => {
    set_loading(true);
    set_error(false);
    getSettings();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    set_error(false);
    try {
      await api.app_settings.update({
        all_regions,
        quarantine_mode,
        quarantine_title,
        quarantine_msg
      });
      toasts.success("Updated");
    } catch (err) {
      set_loading(false);
      set_error(true);
      toasts.error(err.toString());
    }

    getSettings();
  };
  if (error) {
    return (
      <div style={{ padding: 30 }}>
        <h3>App settings</h3>
        <Container>An error occurred</Container>
      </div>
    );
  }
  return (
    <div style={{ padding: 30 }}>
      <h3>App settings</h3>
      {loading && <Loading />}
      {!loading && (
        <form onSubmit={handleSubmit} style={{ padding: "20px 20px 0" }}>
          <FieldRow>
            <label>Quarantine title</label>
            <input
              name="name"
              value={quarantine_title}
              onChange={e => set_quarantine_title(e.target.value)}
            />
          </FieldRow>
          <FieldRow>
            <label>Quarantine message</label>
            <input
              name="name"
              value={quarantine_msg}
              onChange={e => set_quarantine_msg(e.target.value)}
            />
          </FieldRow>
          <FieldRow>
            <label>
              <input
                type="checkbox"
                checked={all_regions}
                onChange={e => {
                  set_all_regions(e.target.checked);
                }}
              />
              All regions
            </label>
          </FieldRow>
          <FieldRow>
            <label>
              <input
                type="checkbox"
                checked={quarantine_mode}
                onChange={e => {
                  set_quarantine_mode(e.target.checked);
                }}
              />
              Quarantine mode
            </label>
          </FieldRow>
          <FormButtonsContainer>
            <ColoredButton type="submit">Save</ColoredButton>
          </FormButtonsContainer>
        </form>
      )}
    </div>
  );
};

export default AppSettings;
