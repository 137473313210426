// @flow
import React from "react";
import Async from "../../components/Async";
import api from "../../services/api";
import type { Activity } from "../../services/api/types";
import Page from "../../components/Page";
import { Button } from "../../components/buttons";
import styled from "styled-components";
import ActivityItem from "./ActivityItem";
import { Dialog } from "@blueprintjs/core";
import ActivityForm from "./ActivityForm";
import toasts from "services/toasts";

const loadActivities = () => api.activities.list();

const PageActions = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;

type P = {};
type S = { dialogOpen: boolean };

class Activities extends React.Component<P, S> {
  state = {
    dialogOpen: false,
    load: false
  };

  closeDialog = () => this.setState({ dialogOpen: false });
  openDialog = () => this.setState({ dialogOpen: true });

  createActivity = async (data: any) => {
    try {
      await api.activities.createActivity(data);
      this.setState({ load: !this.state.load });
      this.closeDialog();
    } catch (e) {
      toasts.error(e.toString());
    }
  };

  deleteActivity = async id => {
    try {
      await api.activities.deleteActivity({ id });
      this.setState({ load: !this.state.load });
    } catch (e) {
      toasts.error(e.toString());
    }
  };

  updateActivity = async ({ id, file, name }) => {
    try {
      await api.activities.updateActivity({ id, file, name });
      this.setState({ load: !this.state.load });
    } catch (e) {
      toasts.error(e.toString());
    }
  };

  render() {
    const { dialogOpen, load } = this.state;
    return (
      <Page title="Activities">
        <PageActions>
          <Button onClick={this.openDialog}>Create Activity</Button>
        </PageActions>
        <Async fetchProps={loadActivities} reload={load}>
          {(activities: Activity[]) =>
            activities.map(activity => (
              <ActivityItem
                key={activity.id}
                activity={activity}
                updateActivity={this.updateActivity}
                deleteActivity={this.deleteActivity}
              />
            ))
          }
        </Async>
        <Dialog
          title="New Activity"
          isOpen={dialogOpen}
          onClose={this.closeDialog}
        >
          <ActivityForm
            onCancel={this.closeDialog}
            onSubmit={this.createActivity}
            photoIsRequired={true}
          />
        </Dialog>
      </Page>
    );
  }
}

export default Activities;
