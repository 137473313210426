// @flow
import React from "react";
import type { Tile as TileType } from "../../../services/api/types";
import { FieldRow } from "../../../components/forms";
import { TileTypes } from "../../../services/api/tiles";
import ShowHideInput from "./ShowHideInput/ShowHideInput";

type P = {
  tile: TileType,
  haveCommonCaption: boolean,
  haveCommonTitle: boolean,
  onChange: TileType => void
};

const TileOptions = (props: P) => {
  const { tile, haveCommonCaption, haveCommonTitle, onChange } = props;

  const handleTypeChange = (e: any) => {
    onChange({ ...tile, type: e.target.value });
  };

  const handleCommonCaption = (e: any) => {
    onChange({ ...tile, common_comment: e.target.value });
  };

  const handleCommonTitle = (e: any) => {
    onChange({ ...tile, common_title: e.target.value });
  };

  return (
    <>
      <FieldRow>
        <label>Tile Type</label>
        <select value={tile.type} onChange={handleTypeChange}>
          {Object.values(TileTypes)
            .map(v => String(v))
            .map(type => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
        </select>
      </FieldRow>
      {tile.type !== "A+A+A" && (
        <FieldRow>
          <label>
            <input
              type="checkbox"
              checked={tile.auto_swipe}
              onChange={e => {
                onChange({ ...tile, auto_swipe: e.target.checked });
              }}
            />
            Auto Swipe
          </label>
        </FieldRow>
      )}
      {haveCommonTitle && (
        <FieldRow>
          <label>Common Title</label>
          <input value={tile.common_title} onChange={handleCommonTitle} />
        </FieldRow>
      )}
      {haveCommonCaption && (
        <FieldRow>
          <label>Common Caption</label>
          <textarea
            rows="3"
            value={tile.common_comment}
            onChange={handleCommonCaption}
          />
        </FieldRow>
      )}
      <FieldRow>
        <label>Show/Hide</label>
        <ShowHideInput
          value={tile.time_data}
          onChange={newShowHide => {
            onChange({ ...tile, time_data: newShowHide });
          }}
        />
      </FieldRow>
    </>
  );
};

export default TileOptions;
