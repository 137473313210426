import { Toaster, Intent } from "@blueprintjs/core";

const TOASTER = Toaster.create({ position: "bottom" });

const toast = intent => message => TOASTER.show({ message, intent });

export default {
  info: toast(Intent.PRIMARY),
  success: toast(Intent.SUCCESS),
  danger: toast(Intent.DANGER),
  error: toast(Intent.DANGER)
};
