// @flow
import React, { Component } from "react";
import Async from "../../components/Async";
import api from "../../services/api";
import styled from "styled-components";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Button, DeleteButton } from "../../components/buttons";
import CreateDialog from "./CreateDialog";
import List from "../../components/List";
import toasts from "../../services/toasts";

const User = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Options = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;

type P = {};
type S = {
  showCreateDialog: boolean
};

class FirstUsers extends Component<P, S> {
  state = {
    showCreateDialog: false
  };

  listing = React.createRef<Async>();

  createUser = async (p: any) => {
    try {
      await api.first_users.invite(p);
      if (this.listing.current) {
        await this.listing.current.refresh();
      }
      this.setState({ showCreateDialog: false });
    } catch (err) {
      const message = err.response.data.error_code ? err.response.data.message : err.toString();
      toasts.error(message);
    }
  };

  deleteFirstUser = async (user_id: any) => {
    await api.first_users.delete({ user_id });
    if (this.listing.current) {
      await this.listing.current.refresh();
    }
  };

  render() {
    return (
      <div style={{ padding: 30 }}>
        <h3>First users</h3>
        <Options>
          <Button onClick={() => this.setState({ showCreateDialog: true })}>
            Create new first user
          </Button>
        </Options>
        <Async fetchProps={api.first_users.list} ref={this.listing}>
          {users => (
            <List>
              {users.map(({ name, phone_number, expired, id }) => (
                <User key={phone_number}>
                  {name}
                  <span>{phone_number}</span>
                  <span>
                    <DeleteButton
                      confirm={`Delete first user ${name} - ${phone_number}?`}
                      onClick={() => this.deleteFirstUser(id)}
                    />
                    {!expired ? (
                      <FaCheck color="green" />
                    ) : (
                      <FaTimes color="grey" />
                    )}
                  </span>
                </User>
              ))}
            </List>
          )}
        </Async>
        {this.state.showCreateDialog && (
          <CreateDialog
            onClose={() => this.setState({ showCreateDialog: false })}
            onSubmit={this.createUser}
          />
        )}
      </div>
    );
  }
}

export default FirstUsers;
