// @flow
import { _post, _put, _delete, _upload } from "./methods";
import type { Photo } from "./types";

const replaceImage = (
  place_id: number,
  photo_id: number,
  file: Blob
): Promise<void> =>
  _upload(`/admin/places/${place_id}/photos/${photo_id}/image`, file, "photo");

const update = async (place_id: number, photo: Photo) => {
  const photo_id = photo.id;
  if (!photo_id) {
    throw new Error("updatePhoto: missing photo ID");
  }

  const promises = [];
  promises.push(_put(`/admin/places/${place_id}/photos/${photo_id}`, photo));
  if (photo.file) {
    promises.push(replaceImage(place_id, photo_id, photo.file));
    delete photo.file;
  }
  await Promise.all(promises);
  return photo;
};

const add = async (place_id: number, photo: Photo) => {
  // Upload doesn't support the whole range of photo fields
  // because the request is form-data and we have nested objects.
  // Rather than trying various hacks to push all the data here,
  // we'll just upload the photo and send the rest of the data in a separate PUT request.
  const { tile_id } = photo;
  if (!tile_id) {
    throw new Error("can't create photo: missing tile_id");
  }
  let result;
  if (photo.file) {
    result = await _upload(
      `/admin/places/${place_id}/photos`,
      photo.file,
      "photo",
      {
        tile_id,
        comment: "" // prevents the backend from throwing 400
      }
    );
  } else if (photo.photo_template_id) {
    const form = new FormData();
    form.append("photo_template_id", photo.photo_template_id.toString());
    form.append("tile_id", tile_id.toString());
    form.append("comment", "");
    result = await _post(`/admin/places/${place_id}/photos`, form);
  } else {
    throw new Error(
      "Can't create photo: both file and photo_template_id are missing"
    );
  }
  if (!result.id) {
    throw new Error("Failed to get new photo ID from backend response");
  }
  photo.id = result.id;
  return update(place_id, photo);
};

const del = (place_id: number, photo: Photo) => {
  if (!photo.id) {
    throw new Error("Can't delete photo: missing ID");
  }
  return _delete(`/admin/places/${place_id}/photos/${photo.id}`);
};

export default { add, update, del };
