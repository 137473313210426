// @flow
import * as React from "react";
import styled from "styled-components";
import { Spinner } from "@blueprintjs/core";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
`;

export const Loading = (p: any) => (
  <Container {...p}>
    <Spinner />
  </Container>
);

type FetchParams = any;
type FetchResult = any;

type P = {
  params?: FetchParams,
  fetchProps: FetchParams => Promise<FetchResult>,
  render?: FetchResult => React.Node,
  children?: FetchResult => React.Node
};
type S = {
  loading: boolean,
  error: any,
  fetchedProps: FetchResult
};

class Async extends React.PureComponent<P, S> {
  state = {
    loading: true,
    error: false,
    fetchedProps: null
  };

  refresh() {
    this.setState({ loading: true, error: false }, async () => {
      try {
        const fetchedProps = await this.props.fetchProps(this.props.params);
        this.setState({ fetchedProps, loading: false });
      } catch (e) {
        this.setState({ error: true, loading: false });
      }
    });
  }

  componentDidMount() {
    const fetchProps = this.props.fetchProps;
    if (!fetchProps) throw new Error("No fetchProps passed to Async");
    this.refresh();
  }

  componentWillReceiveProps(newProps: P) {
    if (newProps.params !== this.props.params) {
      this.refresh();
    }
    if (newProps.reload !== this.props.reload) {
      this.refresh();
    }
  }

  render() {
    const { loading, error, fetchedProps } = this.state;
    if (error) {
      return <Container>An error occurred</Container>;
    }
    if (loading) {
      return <Loading />;
    }
    if (this.props.render) {
      return this.props.render(fetchedProps);
    }
    if (this.props.children) {
      return this.props.children(fetchedProps);
    }
    throw new Error("Need a render prop or children for Async");
  }
}

export default Async;
