import React, { Component } from "react";
import Async from "components/Async";
import api from "services/api";
import User from "./User";
import Page from "../../components/Page";

const Listing = ({ users, onBan, onUnban, loadDetails }) => {
  return (
    <div>
      {users.map(u => (
        <User
          {...u}
          key={u.id}
          onBan={() => onBan(u.id)}
          onUnban={() => onUnban(u.id)}
          loadDetails={() => loadDetails({ user_id: u.id })}
        />
      ))}
    </div>
  );
};

class Users extends Component {
  state = {};

  onBan = async user_id => {
    await api.users.ban({ user_id });
    this.listing.refresh();
  };

  onUnban = async user_id => {
    await api.users.unban({ user_id });
    this.listing.refresh();
  };

  render() {
    return (
      <Page title="Reported Users">
        <Async
          fetchProps={api.users.reported.list}
          ref={a => (this.listing = a)}
        >
          {users => (
            <Listing
              users={users}
              onBan={this.onBan}
              onUnban={this.onUnban}
              loadDetails={api.users.reports.get}
            />
          )}
        </Async>
      </Page>
    );
  }
}

export default Users;
