// @flow
import React from "react";
import Photo from "./Photo";
import ActivityIcon from "./ActivityIcon";

class FilePreview extends React.Component<{ file: Blob }, { src: any }> {
  state = { src: null };

  componentDidMount() {
    this.read();
  }

  componentDidUpdate(prevProps) {
    if (this.props.file !== prevProps.file) {
      this.read();
    }
  }

  read() {
    const { file } = this.props;
    const reader = new FileReader();
    reader.onload = e => this.setState({ src: e.target.result });
    reader.readAsDataURL(file);
  }

  render() {
    const { src } = this.state;
    if (!src) {
      return null;
    }
    return <Photo url={src} thumbnail_url={src} />;
  }
}

type FileChangeEvent = {
  target: {
    files: Blob[]
  }
};

type P = {
  onChange?: FileChangeEvent => void,
  value?: any,
  name?: string,
  required?: boolean,
  activity?: any
};
type S = { file: Blob | null };

class ImageFileInput extends React.Component<P, S> {
  state = { file: null };
  controlled = "value" in this.props;

  handleChange = (e: FileChangeEvent) => {
    if (!this.controlled) {
      this.setState({ file: e.target.files[0] });
    }
    this.props.onChange && this.props.onChange(e);
  };

  render() {
    const file = this.controlled ? this.props.value : this.state.file;

    return (
      <React.Fragment>
        {file && <FilePreview file={file} />}
        {!file && this.props.activity && (
          <div>
            <ActivityIcon
              onClick={this.changeIcon}
              activity={this.props.activity}
              active={false}
            />
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          name={this.props.name}
          required={this.props.required}
          onChange={this.handleChange}
        />
      </React.Fragment>
    );
  }
}

export default ImageFileInput;
