import React, { Component } from "react";
import { FieldRow } from "components/forms";
import CategorySelect from "./CategorySelect";
import Activities from "components/Activities";
import { ColoredButton } from "components/buttons";
import ScheduleInput from "./ScheduleInput";
import { FormButtonsContainer } from "../../components/layout";
import CollapsibleSection from "../../components/CollapsibleSection";

const formatName = name =>
  name
    .split("_")
    .map(x => x[0].toUpperCase() + x.slice(1))
    .join(" ");

class PlaceForm extends Component {
  state = { ...this.props.initValue };

  componentWillReceiveProps(nextProps) {
    if (nextProps.initValue !== this.state) {
      this.setState({ ...nextProps.initValue });
    }
  }

  renderTextField = (name, required = false, type = "text") => {
    return (
      <FieldRow>
        <span>{formatName(name)}</span>
        <input
          type={type}
          required={required}
          value={this.state[name] || ""}
          onChange={e => this.setState({ [name]: e.target.value })}
        />
      </FieldRow>
    );
  };

  submit = e => {
    e.preventDefault();
    this.props.onSubmit(this.state);
  };

  render() {
    const { opening_times } = this.state;

    return (
      <form onSubmit={this.submit}>
        {this.renderTextField("name", true)}
        <FieldRow>
          <span>Activities: </span>
          <Activities
            activities={this.props.activities}
            value={this.state.activity_ids}
            onChange={activity_ids => this.setState({ activity_ids })}
          />
        </FieldRow>

        {this.renderTextField("address")}
        {this.renderTextField("phone_number")}
        {this.renderTextField("website")}
        {this.renderTextField("description")}
        <FieldRow>
          <span>Category: </span>
          <CategorySelect
            value={this.state.category_id}
            categories={this.props.categories}
            onChange={category_id => this.setState({ category_id })}
          />
        </FieldRow>
        <FieldRow>
          <label>
            <input
              type="checkbox"
              checked={this.state.temporary_closed}
              onChange={e => {
                this.setState({ temporary_closed: e.target.checked });
              }}
            />
            Temporary closed
          </label>
        </FieldRow>
        <CollapsibleSection
          title="Working Hours"
          content={
            <ScheduleInput
              value={opening_times}
              onChange={opening_times => this.setState({ opening_times })}
            />
          }
        />

        <FormButtonsContainer>
          <ColoredButton type="submit">Save</ColoredButton>
        </FormButtonsContainer>
      </form>
    );
  }
}

export default PlaceForm;
