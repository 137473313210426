// @flow
import React, { useState } from "react";
import type {
  Photo as PhotoType,
  PhotoTemplate
} from "../../../services/api/types";
import FormDialog from "../../../components/FormDialog";
import photoTemplates from "../../../services/api/photo-templates";
import Photo from "../../../components/Photo";
import { FieldRow } from "../../../components/forms";

type P = {
  isOpen: boolean,
  photo: PhotoType,
  onClose: void => any,
  initialTemplate: PhotoTemplate
};

const PhotoTemplateEditor = (props: P) => {
  const { isOpen, onClose, initialTemplate, photo } = props;

  if (!photo.id) {
    return null;
  }

  const [template, setTemplate] = useState(initialTemplate);

  const save = async () => {
    await photoTemplates.add(template);
  };

  const change = k => e => {
    setTemplate({ ...template, [k]: e.target.value });
  };

  return (
    <FormDialog
      title="Make Template"
      isOpen={isOpen}
      onClose={onClose}
      onAccept={save}
      closeButtonLabel="Cancel"
      acceptButtonLabel="Save"
    >
      {photo.photo_url && photo.thumbnail_url && (
        <Photo url={photo.photo_url} thumbnail_url={photo.thumbnail_url} />
      )}
      <FieldRow>
        <label>Title</label>
        <input name="title" value={template.title} onChange={change("title")} />
      </FieldRow>

      <FieldRow>
        <label>Comment</label>
        <textarea
          rows="3"
          name="comment"
          value={template.comment}
          onChange={change("comment")}
        />
      </FieldRow>
    </FormDialog>
  );
};

export default PhotoTemplateEditor;
