// @flow
import React from "react";
import styled from "styled-components";
import { Card, HTMLTable } from "@blueprintjs/core";
import WorkingHours from "./WorkingHours";
import { DAYS } from "../days";
import type { WorkingTime } from "../../../services/api/types";

const OpeningTimesContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 8px;
  }
`;

type P = {
  value: WorkingTime[],
  onChange: (WorkingTime[]) => void
};

const ScheduleInput = ({ value, onChange }: P) => {
  const handleDayToggle = day => e => {
    const newRows = value.filter(x => x.day !== day);
    if (e.target.checked) {
      newRows.push({
        day,
        open_all_day: true,
        opening_time: "06:00",
        closing_time: "22:00"
      });
    }
    onChange(newRows);
  };

  const handleOpenAllDayChange = day => e => {
    onChange(
      value.map(x => {
        if (x.day === day) {
          return { ...x, open_all_day: e.target.checked };
        }
        return x;
      })
    );
  };

  const setDayEntries = (day, periods) => {
    const entries = periods.map(x => ({
      day: day,
      open_all_day: false,
      opening_time: x.start,
      closing_time: x.end
    }));
    onChange(value.filter(x => x.day !== day).concat(entries));
  };

  return (
    <Card>
      <HTMLTable bordered>
        <thead />
        <tbody>
          {DAYS.map(({ day, name }) => {
            const dayEntries = value.filter(x => x.day === day);
            const haveEntries = dayEntries.length > 0;
            const openAllDay = dayEntries.some(x => x.open_all_day);

            return (
              <tr key={day}>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      checked={haveEntries}
                      onChange={handleDayToggle(day)}
                    />
                    <b>{name}</b>
                  </label>
                </td>
                <td>
                  {haveEntries && (
                    <label>
                      <input
                        type="checkbox"
                        checked={openAllDay}
                        onChange={handleOpenAllDayChange(day)}
                      />
                      Open all day
                    </label>
                  )}

                  {haveEntries && !openAllDay && (
                    <>
                      <h5>Schedule</h5>
                      <OpeningTimesContainer>
                        <WorkingHours
                          values={dayEntries.map(x => ({
                            start: x.opening_time,
                            end: x.closing_time
                          }))}
                          onChange={newEntries =>
                            setDayEntries(day, newEntries)
                          }
                        />
                      </OpeningTimesContainer>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    </Card>
  );
};

export default ScheduleInput;
