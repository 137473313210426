// @flow
import { Dialog } from "./dialogs";
import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;

  & > img {
    cursor: ${props => (props.hasZoom ? "zoom-in" : "default")};
    max-height: 120px;
    max-width: 120px;
  }
`;

type P = {
  url: string,
  thumbnail_url: string
};

const Photo = (props: P) => {
  const { url, thumbnail_url } = props;
  const hasZoom = !!url;

  const [zoom, setZoom] = useState(false);
  const zoomIn = () => setZoom(true);
  const zoomOut = () => setZoom(false);

  return (
    <React.Fragment>
      <Container hasZoom={hasZoom}>
        <img
          src={thumbnail_url}
          alt="gallery-pic"
          onClick={hasZoom ? zoomIn : null}
        />
      </Container>

      {zoom && (
        <Dialog onClose={zoomOut}>
          <img src={url} alt="larger" />
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default Photo;
