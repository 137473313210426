import axios from "axios";
import store from "store";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

const client = axios.create({
  baseURL: API_URL
});

client.interceptors.request.use(
  function(config) {
    const token = store.getState().auth.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const _get = async (endpoint, params) => {
  return (await client.get(endpoint, { params })).data;
};

export const _delete = async (endpoint, params) => {
  return (await client.delete(endpoint, { params })).data;
};

export const _post = async (endpoint, params) => {
  return (await client.post(endpoint, params)).data;
};

export const _put = async (endpoint, params) => {
  return (await client.put(endpoint, params)).data;
};

export const _patch = async (endpoint, params) => {
  return (await client.patch(endpoint, params)).data;
};

export const _upload = async (endpoint, file, fileName, extra = {}) => {
  const data = new FormData();
  data.append(fileName, file);
  Object.entries(extra).forEach(([k, v]) => data.append(k, v));
  return (await client.post(endpoint, data)).data;
};

export const _update = async (endpoint, file, fileName, extra = {}) => {
  const data = new FormData();
  if (file) {
    data.append(fileName, file);
  }
  Object.entries(extra).forEach(([k, v]) => data.append(k, v));
  return (await client.patch(endpoint, data)).data;
};
