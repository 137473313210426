// @flow
import React from "react";
import type { Showtime as ShowtimeType } from "../../../../services/api/types";
import Times from "./Times";
import Showtimes from "./Showtimes";
import { Tabs, Tab } from "@blueprintjs/core";

type Val = {
  default_showtimes: string[],
  showtimes: ShowtimeType[]
};

type P = {
  time_data: Val,
  onChange: Val => void
};

const TimeData = (props: P) => {
  const { time_data, onChange } = props;
  const update = field => (value: any) => {
    onChange({ ...time_data, [field]: value });
  };
  return (
    <Tabs>
      <Tab
        id="default-showtimes"
        title="Default"
        panel={
          <section>
            <Times
              value={time_data.default_showtimes}
              onChange={update("default_showtimes")}
            />
          </section>
        }
      />
      <Tab
        id="date-showtimes"
        title="Date-Specific"
        panel={
          <Showtimes
            value={time_data.showtimes}
            onChange={update("showtimes")}
          />
        }
      />
    </Tabs>
  );
};

export default TimeData;
