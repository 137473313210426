import React from "react";
import styled from "styled-components";
import { Dialog as BpDialog } from "@blueprintjs/core";
import { Button, ColoredButton } from "components/buttons";

const StyledDialog = styled(BpDialog)`
  background-color: white;
  border-radius: 0;
  padding: 30px;
`;

export const Dialog = p => {
  return <StyledDialog isOpen {...p} />;
};

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
`;

const DialogContent = styled.div`
  flex: 1;
`;

const DivContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CancellableDialog = ({
  onSubmit,
  onClose,
  children,
  isForm,
  ...rest
}) => {
  const Container = isForm ? FormContainer : DivContainer;
  const cleanSubmit = e => {
    e.preventDefault();
    onSubmit(e);
  };
  return (
    <Dialog {...rest} onClose={onClose}>
      <Container onSubmit={cleanSubmit}>
        <DialogContent>{children}</DialogContent>
        <ButtonRow>
          <Button type="button" onClick={onClose}>
            Cancel
          </Button>
          <ColoredButton onClick={isForm ? () => {} : onSubmit} type="submit">
            OK
          </ColoredButton>
        </ButtonRow>
      </Container>
    </Dialog>
  );
};
