// @flow
import { Collapse } from "@blueprintjs/core";
import React from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import styled from "styled-components";
import colors from "../../styles/colors";
import PlaceDetails from "./PlaceDetails";
import places from "../../services/api/places";
import type { Place } from "../../services/api/types";

const IconContainer = styled.div`
  margin-right: 15px;
  cursor: pointer;
`;

const CollapseContent = styled.div`
  padding: 20px;
  background-color: ${props =>
    props.curated ? "rgba(153, 255, 204, 0.1)" : "rgba(0, 0, 0, 0.01)"};
`;

const Icon = ({ curated, onClick }) => {
  return (
    <IconContainer onClick={onClick}>
      {curated ? <FaStar color={colors.playgroundGreen} /> : <FaRegStar />}
    </IconContainer>
  );
};

const ItemContainer = styled.div`
  &:first-child {
    border-top: 1px rgba(0, 0, 0, 0.05) solid;
  }
`;

const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  border-bottom: 1px rgba(0, 0, 0, 0.05) solid;
  cursor: pointer;
  background-color: ${({ curated }) =>
    curated ? "rgba(153, 255, 204, 0.2)" : "white"};
`;

type P = {
  id: number,
  curated: boolean,
  name: string,
  activities: any,
  categories: any,
  onClick: () => void,
  onCuration: () => void,
  onPlaceUpdate: () => void,
  onPhotoDelete: string => void
};
type S = { details: Place | null, open: boolean };

class Item extends React.Component<P, S> {
  state = {
    details: null,
    open: false
  };

  loadPlaceDetails = async () => {
    if (!this.props.curated) {
      return;
    }
    this.setState({
      details: await places.get(this.props.id)
    });
  };

  onHeaderClick = async () => {
    if (!this.state.open) {
      this.loadPlaceDetails();
    }
    this.setState({ open: !this.state.open });
    this.props.onClick();
  };

  onCurateClick = (e: Event) => {
    e.stopPropagation();
    this.props.onCuration();
  };

  setProfilePicture = async (file: Blob) => {
    const place_id = this.props.id;
    await places.uploadProfilePicture({ place_id, file });
    this.loadPlaceDetails();
  };

  onPhotoDelete = async (id: string) => {
    await this.props.onPhotoDelete(id);
    this.loadPlaceDetails();
  };

  render() {
    const { name, curated, activities, categories, onPlaceUpdate } = this.props;
    const { details, open } = this.state;

    return (
      <ItemContainer>
        <ItemHeader curated={curated} onClick={this.onHeaderClick}>
          <Icon curated={curated} onClick={this.onCurateClick} />
          {name}
        </ItemHeader>
        <Collapse isOpen={open} style={{ backgroundColor: "rgba(0,0,0,0.05)" }}>
          <CollapseContent curated={curated}>
            {curated ? (
              <PlaceDetails
                place_id={this.props.id}
                curated={curated}
                activities={activities}
                categories={categories}
                details={details}
                onPlaceUpdate={onPlaceUpdate}
                onPhotoDelete={this.onPhotoDelete}
                setProfilePicture={file => {
                  this.setProfilePicture(file);
                }}
              />
            ) : (
              <p>Curate this place to make it available in the app</p>
            )}
          </CollapseContent>
        </Collapse>
      </ItemContainer>
    );
  }
}

export default Item;
