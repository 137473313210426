// @flow
import React from "react";
import type { Showtime as ShowtimeType } from "../../../../services/api/types";
import Periods from "../Periods";
import Times from "./Times";

const Showtime = (props: {
  value: ShowtimeType,
  onChange: ShowtimeType => void
}) => {
  const { value, onChange } = props;
  const change = field => (val: any) => {
    onChange({ ...value, [field]: val });
  };

  return (
    <div>
      <div>
        <label>Dates</label>
        <Periods value={value.dates} onChange={change("dates")} />
      </div>
      <div>
        <label>Values</label>
        <Times value={value.values} onChange={change("values")} />
      </div>
    </div>
  );
};

export default Showtime;
