// @flow
import { _get, _post, _put, _delete } from "./methods";
import photos from "./photos";
import type { Tile, Photo } from "./types";
import moment from "moment";

// Different types of tiles are declared explicitly.
// The notation is "Title/Image/Caption".
// "A+A/B/C+A" means three images, all with the same title and caption.
export const TileTypes = {
  Single: "A+A+A",
  CommonTitle: "A+A/B/C+A/B/C",
  CommonTitleAndCaption: "A+A/B/C+A",
  CommonNothing: "A/B/C+A/B/C+A/B/C"
};

/**
 * Removes timezone specifier from an ISO datetime string.
 */
export const removeTimezone = (str: string) => {
  const m = str.match(/\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d/);
  if (!m || !m.length) {
    return str;
  }
  return m[0];
};

/**
 * Formats a date object to a local datetime string as the user
 * would see it.
 */
export const formatLocalDatetime = (date: Date) => {
  return removeTimezone(
    moment(date)
      .local()
      .format()
  );
};

// Makes the app's life easier by fixing the backend's messy response.
const fixTile = (tile: Tile): Tile => {
  if (!tile.time_data) {
    tile.time_data = {
      show: [],
      hide: []
    };
  }
  tile.photos = tile.photos.sort(
    (a: Photo, b: Photo) => a.tile_order - b.tile_order
  );
  for (const p of tile.photos) {
    if (!p.time_data) {
      p.time_data = {
        show: [],
        hide: [],
        default_showtimes: [],
        showtimes: []
      };
    }
  }
  return tile;
};

export default {
  list: async (place_id: number) => {
    // We don't have a separate query for tiles, so
    // we query place data and get the tiles from there.
    const placeDetails = await _get(`/admin/places/${place_id}`);
    return (
      placeDetails.place_tiles
        .map(fixTile)
        .sort((a, b) => a.order - b.order)
        // Renumber the tiles in case the ones in the response
        // have messed up order values.
        .map((tile, i) => ({ ...tile, order: i }))
    );
  },

  add: (place_id: number, tile: Tile) =>
    _post(`/admin/places/${place_id}/tiles`, tile),

  update: (place_id: number, tile: Tile) => {
    const tile_id = tile.id;
    if (!tile_id) {
      throw new Error("update: missing tile ID");
    }
    _put(`/admin/places/${place_id}/tiles/${tile_id}`, tile);
  },

  delete: (place_id: number, tile_id: number) =>
    _delete(`/admin/places/${place_id}/tiles/${tile_id}`),

  photos
};
