// @flow
import React from "react";
import styled from "styled-components";
import ActivityIcon from "./ActivityIcon";
import type { Activity } from "../services/api/types";

const Container = styled.div`
  display: flex;
  & > * {
    margin-right: 8px;
    &:last-child {
      margin-right: none;
    }
  }
  margin-left: 12px;
`;

const onActivityChange = (placeActivityIds, activityId) => {
  if (placeActivityIds.includes(activityId)) {
    return placeActivityIds.filter(i => i !== activityId);
  } else {
    return placeActivityIds.concat([activityId]);
  }
};

type P = {
  activities: Activity[],
  value: any,
  onChange: void => void
};

export default ({ activities, value, onChange }: P) => {
  return (
    <Container>
      {activities.map(activity => (
        <ActivityIcon
          key={activity.id}
          activity={activity}
          active={value.includes(activity.id)}
          onClick={() => onChange(onActivityChange(value, activity.id))}
        />
      ))}
    </Container>
  );
};
