import React from "react";
import { FieldRow } from "components/forms";
import View from "./View";
import api from "services/api";
import { connect } from "react-redux";
import history from "services/history";

class Login extends React.Component {
  state = {
    email: "",
    password: ""
  };
  login = async e => {
    try {
      const data = await api.auth.login(this.state);
      this.props.login(data);
      history.push("/");
    } catch (e) {
      console.warn(e);
    }
  };
  render() {
    return (
      <View onSubmit={this.login} title="Sign in">
        <FieldRow>
          <div>Email</div>
          <input
            type="email"
            required
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
          />
        </FieldRow>
        <FieldRow>
          <div>Password</div>
          <input
            type="password"
            required
            value={this.state.password}
            onChange={e => this.setState({ password: e.target.value })}
          />
        </FieldRow>
      </View>
    );
  }
}

const dispatcher = dispatch => ({
  login: data => dispatch({ type: "LOGIN", data })
});

export default connect(
  null,
  dispatcher
)(Login);
