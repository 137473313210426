import React from "react";
import ImageFileInput from "../../components/ImageFileInput";
import { FormButtonsContainer } from "../../components/layout";
import { ColoredButton } from "../../components/buttons";
import Photo from "../../components/Photo";

class ProfilePicture extends React.Component {
  state = {
    changed: false
  };

  handleSubmit = async e => {
    const { onSubmit } = this.props;
    e.preventDefault();
    const file = e.target.querySelector('[name="file"]').files[0];
    if (!file) {
      return;
    }
    onSubmit(file);
  };

  render() {
    const { picture } = this.props;
    const { changed } = this.state;

    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          {picture && !changed && (
            <Photo
              url={picture.photo_url}
              thumbnail_url={picture.thumbnail_url}
            />
          )}
          <ImageFileInput
            onChange={() => this.setState({ changed: true })}
            key={picture ? picture.photo_url : "none"}
            name="file"
            required
          />
          <FormButtonsContainer>
            <ColoredButton type="submit" disabled={!changed}>
              Save
            </ColoredButton>
          </FormButtonsContainer>
        </form>
      </React.Fragment>
    );
  }
}

export default ProfilePicture;
