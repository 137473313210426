import React, { Component } from "react";
import { DeleteButton } from "../../components/buttons";
import ActivityIcon from "../../components/ActivityIcon";
import styled from "styled-components";
import ActivityForm from "./ActivityForm";
import CollapsibleSection from "../../components/CollapsibleSection";

const Div = styled.div`
  margin-left: 8px;
  display: inline-flex;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default class ActivityItem extends Component {
  state = {
    open: false
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  onSubmit = fields => {
    this.props.updateActivity({
      id: this.props.activity.id,
      file: fields.file,
      name: fields.name
    });
  };

  render() {
    const { activity, deleteActivity } = this.props;
    return (
      <div>
        <CollapsibleSection
          title={
            <Wrapper>
              <DeleteButton
                confirm={`Delete activity ${activity.name}?`}
                onClick={() => deleteActivity(this.props.activity.id)}
              />
              <ActivityIcon activity={activity} active={false} />
              <Div>{activity.name}</Div>
            </Wrapper>
          }
          content={
            <React.Fragment>
              <ActivityForm edit onSubmit={this.onSubmit} activity={activity} />
            </React.Fragment>
          }
        />
      </div>
    );
  }
}
