import React, { Component } from "react";
import { CancellableDialog } from "components/dialogs";
import { FieldRow } from "components/forms";

class CreateDialog extends Component {
  state = { name: "", phone_number: "" };
  render() {
    const { onClose, onSubmit } = this.props;
    const { name, phone_number } = this.state;
    return (
      <CancellableDialog
        isForm
        onClose={onClose}
        onSubmit={() => onSubmit(this.state)}
      >
        <h5>Invite first user</h5>
        <FieldRow>
          <span>Name</span>
          <input
            type="text"
            value={name}
            required
            onChange={e => this.setState({ name: e.target.value })}
          />
        </FieldRow>
        <FieldRow>
          <span>Phone number</span>
          <input
            type="text"
            value={phone_number}
            required
            onChange={e => this.setState({ phone_number: e.target.value })}
          />
        </FieldRow>
      </CancellableDialog>
    );
  }
}

export default CreateDialog;
