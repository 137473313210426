// @flow
import * as React from "react";
import styled from "styled-components";
import { MdDragHandle } from "react-icons/md";

const Container = styled.div`
  display: block;
  padding-left: 2em;
  position: relative;
`;

const withProps = props => C => p => <C {...props} {...p} />;

const Handle = withProps({ children: <MdDragHandle /> })(styled.div`
  width: 2em;
  position: absolute;
  left: 0;
  top: calc(50% - 10px);
  cursor: ${props => (props.disabled ? "default" : "ns-resize")};
  opacity: ${props => (props.disabled ? "0.3" : "1")};
`);

type P = {
  children: React.Node,
  onSwap: (number, number) => void,
  id: string,
  disabled?: boolean
};

const SortableList = (props: P) => {
  const { children, onSwap, id, disabled } = props;

  const cc = React.Children.toArray(children);

  return (
    <div>
      {cc.map((child, i) => (
        <Container
          key={i}
          draggable={!disabled}
          onDragStart={e => {
            e.dataTransfer.setData("text/plain", `${i}:${id}`);
            e.stopPropagation();
          }}
          onDragOver={e => {
            e.preventDefault();
          }}
          onDrop={e => {
            e.preventDefault();
            e.dataTransfer.items[0].getAsString(val => {
              const [dragIndex, listId] = val.split(":");
              if (listId !== id) {
                return;
              }
              onSwap(Number(dragIndex), i);
            });
          }}
        >
          <Handle disabled={disabled} />
          {child}
        </Container>
      ))}
    </div>
  );
};

export default SortableList;
