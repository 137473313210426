import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router";
import Login from "screens/auth/Login";
import Signup from "screens/auth/Signup";
import Home from "screens/Home";
import Places from "screens/Places";
import Users from "screens/Users";
import Admins from "screens/Admins";
import Categories from "screens/Categories";
import Activities from "./screens/Activities";
import AppSettings from "./screens/AppSettings";
import FirstUsers from "./screens/FirstUsers";
import AccessCodes from "./screens/AccesCodes";
import UsersInfo from "./screens/UsersInfo";
import WaitingList from "./screens/WaitingList";
import DeleteList from "./screens/DeleteList";
import PushNotifications from "./screens/PushNotifications";
import ReferrerUsers from "./screens/ReferrerUsers";

const AuthRouteStupid = ({ connected, ...rest }) => {
  if (connected) {
    return <Route {...rest} />;
  } else {
    return <Redirect to="/login" />;
  }
};

const mapStateToProps = state => {
  return {
    connected: !!state.auth.token
  };
};

const AuthRoute = connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(AuthRouteStupid);

const Routes = props => {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      {/* <AuthRoute path="/places" component={Places} /> */}
      <AuthRoute path="/london" component={Places} />
      <AuthRoute path="/paris" component={Places} />
      <AuthRoute path="/dubai" component={Places} />
      <AuthRoute path="/madrid" component={Places} />
      <AuthRoute path="/new_york" component={Places} />
      <AuthRoute path="/tel_aviv" component={Places} />
      <AuthRoute path="/lisbon" component={Places} />
      <AuthRoute path="/brussels" component={Places} />
      <AuthRoute path="/rio" component={Places} />
      <AuthRoute path="/beirut" component={Places} />
      <AuthRoute path="/milan" component={Places} />
      <AuthRoute path="/roma" component={Places} />
      <AuthRoute path="/athens" component={Places} />
      <AuthRoute path="/amsterdam" component={Places} />
      <AuthRoute path="/barcelona" component={Places} />
      <AuthRoute path="/berlin" component={Places} />
      <AuthRoute path="/mykonos" component={Places} />
      <AuthRoute path="/users" component={Users} />
      <AuthRoute path="/admins" component={Admins} />
      <AuthRoute path="/first_users" component={FirstUsers} />
      <AuthRoute path="/push_notifications" component={PushNotifications} />
      <AuthRoute path="/users_info" component={UsersInfo} />
      <AuthRoute path="/waiting_list" component={WaitingList} />
      <AuthRoute path="/delete_list" component={DeleteList} />
      <AuthRoute path="/referrer_users" component={ReferrerUsers} />
      <AuthRoute path="/access_codes" component={AccessCodes} />
      <AuthRoute path="/categories" component={Categories} />
      <AuthRoute path="/activities" component={Activities} />
      <AuthRoute path="/app_settings" component={AppSettings} />
      <AuthRoute path="/" component={Home} />
    </Switch>
  );
};

export default Routes;
