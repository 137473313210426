// @flow
import * as React from "react";
import styled from "styled-components";
import { Collapse } from "@blueprintjs/core";

const Container = styled.div`
  margin-bottom: 8px;
`;

const Header = styled.div`
  display: flex;
  padding: 12px 12px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  border-left: none;
  border-right: none;
  background: rgba(0, 0, 0, 0.03);
  cursor: pointer;
  font-weight: bold;
`;

const Details = styled.div`
  padding: 15px;
  background: rgba(0, 0, 0, 0.02);
`;

type S = {
  open: boolean
};
type P = {
  title: React.Node,
  content: React.Node,
  onOpen?: void => void
};

class CollapsibleSection extends React.Component<P, S> {
  state = { open: false };
  toggle = () => {
    if (!this.state.open && this.props.onOpen) {
      this.props.onOpen();
    }
    this.setState({ open: !this.state.open });
  };

  render() {
    const { title, content } = this.props;

    return (
      <Container>
        <Header onClick={this.toggle}>{title}</Header>

        <Collapse isOpen={this.state.open} style={{ padding: 15 }}>
          <Details>{content}</Details>
        </Collapse>
      </Container>
    );
  }
}

export default CollapsibleSection;
