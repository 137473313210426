const EARTH_RADIUS = 3963.0 * 1000;

export const getRadius = ({ center, bounds }) => {
  // r = radius of the earth in statute miles
  const { ne } = bounds;
  // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
  const lat1 = center.lat / 57.2958;
  const lon1 = center.lng / 57.2958;
  const lat2 = ne.lat / 57.2958;
  const lon2 = ne.lng / 57.2958;

  // distance = circle radius from center to Northeast corner of bounds
  return (
    EARTH_RADIUS *
    Math.acos(
      Math.sin(lat1) * Math.sin(lat2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
    )
  );
};
