// @flow
import { _get, _post, _put, _delete } from "./methods";
import type { PhotoTemplate } from "./types";

const add = (template: PhotoTemplate) => {
  return _post("/admin/places/photo_templates", template);
};

const list = () => {
  return _get("/admin/places/photo_templates");
};

const update = (template: PhotoTemplate) => {
  if (!template.id) {
    throw new Error("can't update: missing template ID");
  }
  return _put(`/admin/places/photo_templates/${template.id}`, template);
};

const del = (template: PhotoTemplate) => {
  if (!template.id) {
    throw new Error("can't delete: missing template ID");
  }
  return _delete(`/admin/places/photo_templates/${template.id}`);
};

export default { add, list, update, del };
