// @flow
import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 30px;
  height: 100%;
  width: 100%;
`;

type P = {
  title: string,
  children: React.Node
};

const Page = (props: P) => {
  const { title, children } = props;

  return (
    <Container>
      <h3>{title}</h3>
      {children}
    </Container>
  );
};

export default Page;
