// @flow
import { Dialog } from "@blueprintjs/core";
import * as React from "react";
import { Button } from "./buttons";

type P = {
  onAccept?: void => any,
  acceptButtonLabel?: string,
  onClose: void => any,
  closeButtonLabel?: string,
  children: React.Node,
  isOpen: boolean,
  title: string
};

const FormDialog = (props: P) => {
  const {
    onAccept,
    acceptButtonLabel = "Accept",
    onClose,
    closeButtonLabel = "Close",
    children,
    isOpen,
    title,
    ...rest
  } = props;

  return (
    <Dialog {...rest} title={title} isOpen={isOpen} onClose={onClose}>
      <div className="bp3-dialog-body">{children}</div>
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          {onAccept && (
            <Button
              onClick={() => {
                onClose();
                onAccept();
              }}
            >
              {acceptButtonLabel}
            </Button>
          )}
          <Button onClick={onClose}>{closeButtonLabel}</Button>
        </div>
      </div>
    </Dialog>
  );
};

export default FormDialog;
