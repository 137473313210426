// @flow
import { TimePicker } from "@blueprintjs/datetime";
import React from "react";
import moment from "moment";

const stringToDate = str =>
  moment("1970-01-01T" + (str || "00:00:00")).toDate();
const dateToString = date =>
  moment(date)
    .local()
    .format("HH:mm");

type P = {
  value: string,
  onChange: string => void
};

const TimeInput = (props: P) => {
  const { value, onChange } = props;

  return (
    <TimePicker
      showArrowButtons
      value={stringToDate(value)}
      onChange={val => onChange(dateToString(val))}
    />
  );
};

export default TimeInput;
