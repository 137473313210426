// @flow
import React, { Component } from "react";
import api from "../../services/api";
import styled from "styled-components";
import CreationForm from "./CreationForm";
import Category from "./Category";
import Page from "../../components/Page";
import { Dialog } from "@blueprintjs/core";
import { Button } from "../../components/buttons";

const Options = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;

type P = {};
type S = {
  categories: any,
  activities: any,
  loading: boolean,
  dialogOpen: boolean
};

class Categories extends Component<P, S> {
  state = {
    categories: null,
    activities: null,
    loading: true,
    dialogOpen: false
  };

  componentDidMount = () => this.loadData();

  loadData = async () => {
    const [cats, activities] = await Promise.all([
      api.categories.list(),
      api.activities.list()
    ]);
    const categories = cats.concat().sort((x, y) => y.count - x.count);
    this.setState({ categories, activities, loading: false });
  };

  createCategory = async (d: any) => {
    this.closeDialog();
    await api.categories.create(d);
    await this.loadData();
  };

  deleteCategory = async (category_id: any) => {
    await api.categories.delete({ category_id });
    await this.loadData();
  };

  onDefaultActivitiesChange = async (category_id: any, activity_ids: any) => {
    const { categories } = this.state;
    if (!categories) {
      return;
    }

    const changedCategories = categories.map(c =>
      c.id === category_id ? { ...c, default_activity_ids: activity_ids } : c
    );
    await api.categories.setDefaultActivities({ category_id, activity_ids });
    this.setState({ categories: changedCategories });
  };

  closeDialog = () => this.setState({ dialogOpen: false });
  openDialog = () => this.setState({ dialogOpen: true });

  render() {
    const { activities, categories, loading, dialogOpen } = this.state;

    return (
      <Page title="Categories (Places)">
        <Options>
          <Button onClick={this.openDialog}>Create Category</Button>
        </Options>
        <Dialog
          title="New Category"
          isOpen={dialogOpen}
          onClose={this.closeDialog}
        >
          <CreationForm
            onSubmit={this.createCategory}
            onCancel={this.closeDialog}
            activities={activities}
          />
        </Dialog>

        {!loading &&
          categories &&
          categories.map(({ id, name, count, default_activity_ids }) => (
            <Category
              category={{ id, name, count, default_activity_ids }}
              key={name}
              onDelete={() => {
                this.deleteCategory(id);
              }}
              activities={activities}
              onDefaultActivitiesChange={activity_ids => {
                this.onDefaultActivitiesChange(id, activity_ids);
              }}
            />
          ))}
      </Page>
    );
  }
}

export default Categories;
