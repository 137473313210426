import React, { Component } from "react";
import { FieldRow } from "components/forms";
import { ColoredButton } from "components/buttons";
import Activities from "components/Activities";
import { Button } from "../../components/buttons";

class CreationForm extends Component {
  state = { name: "", activity_ids: [] };
  submit = e => {
    e.preventDefault();
    this.props.onSubmit(this.state);
  };
  render() {
    const { onCancel } = this.props;

    return (
      <form onSubmit={this.submit} style={{ padding: "20px 20px 0" }}>
        <FieldRow>
          <span>Name:</span>
          <input
            type="text"
            value={this.state.name}
            onChange={e => this.setState({ name: e.target.value })}
            required
          />
        </FieldRow>
        <FieldRow>
          <span>Default activities: </span>
          <Activities
            activities={this.props.activities}
            value={this.state.activity_ids}
            onChange={activity_ids => this.setState({ activity_ids })}
          />
        </FieldRow>
        <div
          style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}
        >
          <Button type="button" onClick={onCancel}>
            Cancel
          </Button>
          <ColoredButton type="submit">Create</ColoredButton>
        </div>
      </form>
    );
  }
}

export default CreationForm;
