// @flow
import React from "react";
import styled from "styled-components";
import { DeleteButton, Button } from "../../../components/buttons";
import CollapsibleSection from "../../../components/CollapsibleSection";
import type { Tile as TileType, Photo } from "../../../services/api/types";
import { TileTypes } from "../../../services/api/tiles";
import TileOptions from "./TileOptions";
import SortableList from "./SortableList";
import { spacing } from "../../../components/layout";
import TilePhotoEditor from "./TilePhotoEditor/TilePhotoEditor";
import { remove, replace, swap } from "../../../minus";

const TileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const PhotoEditorContainer = styled.div`
  margin-bottom: ${spacing};
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`;

const TileOptionsContainer = styled.div`
  margin-bottom: ${spacing};
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`;

type P = {
  tile: TileType,
  onChange: TileType => void,
  onDelete: void => void
};

const Tile = (props: P) => {
  const { tile, onDelete, onChange } = props;
  const haveCommonTitle =
    tile.type === TileTypes.CommonTitle ||
    tile.type === TileTypes.CommonTitleAndCaption;
  const haveCommonCaption = tile.type === TileTypes.CommonTitleAndCaption;

  const handlePhotosChange = (photos: Photo[]) => {
    onChange({ ...tile, photos });
  };

  const addPhoto = () => {
    const tile_id = tile.id;
    const newPhoto = {
      title: "",
      comment: "",
      tile_order: 0,
      times: [],
      tile_id,
      time_data: {
        show: [],
        hide: [],
        default_showtimes: [],
        showtimes: []
      }
    };

    // Set the new tile's order as zero, but in local state add it at the bottom of the list.
    // After saving the list will refresh.
    handlePhotosChange(
      tile.photos
        .map(p => ({ ...p, tile_order: p.tile_order + 1 }))
        .concat([newPhoto])
    );
  };

  const removePhoto = i => {
    handlePhotosChange(remove(tile.photos, i));
  };

  const updatePhoto = (i, diff) => {
    handlePhotosChange(replace(tile.photos, i, { ...tile.photos[i], ...diff }));
  };

  const swapPhotos = (i, j) => {
    const swapped = swap(tile.photos, i, j).map((p, i) => ({
      ...p,
      tile_order: i
    }));
    handlePhotosChange(swapped);
  };

  const orderViolated = () => {
    for (let i = 1; i < tile.photos.length; i++) {
      if (tile.photos[i].tile_order <= tile.photos[i - 1].tile_order) {
        return true;
      }
    }
    return false;
  };

  return (
    <CollapsibleSection
      title={
        <TileHeader>
          <span>
            Tile {tile.order} ({tile.photos.length}{" "}
            {tile.photos.length === 1 ? "photo" : "photos"})
          </span>
          <DeleteButton
            confirm={tile.photos.length > 0 ? "Delete the tile?" : null}
            onClick={onDelete}
          />
        </TileHeader>
      }
      content={
        <>
          <TileOptionsContainer>
            <h5>Tile options</h5>
            <TileOptions
              tile={tile}
              haveCommonCaption={haveCommonCaption}
              haveCommonTitle={haveCommonTitle}
              onChange={onChange}
            />
          </TileOptionsContainer>
          <div>
            <h5>Tile Photos</h5>
            <SortableList
              id="sortable-photos"
              onSwap={swapPhotos}
              disabled={orderViolated()}
            >
              {tile.photos.map((photo, i) => (
                <PhotoEditorContainer key={i}>
                  <TilePhotoEditor
                    haveCommonCaption={haveCommonCaption}
                    haveCommonTitle={haveCommonTitle}
                    photo={photo}
                    onChange={diff => {
                      updatePhoto(i, diff);
                    }}
                    onDelete={() => {
                      removePhoto(i);
                    }}
                  />
                </PhotoEditorContainer>
              ))}
            </SortableList>
            <Button onClick={addPhoto}>Add Photo</Button>
          </div>
        </>
      }
    />
  );
};

export default Tile;
