// @flow
import React, { Component } from "react";
import Async from "../../components/Async";
import api from "../../services/api";
import styled from "styled-components";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Button } from "../../components/buttons";
import CreateDialog from "./CreateDialog";
import List from "../../components/List";

const User = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Options = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;

type P = {};
type S = {
  showCreateDialog: boolean
};

class Admins extends Component<P, S> {
  state = {
    showCreateDialog: false
  };

  listing = React.createRef<Async>();

  createUser = async (p: any) => {
    await api.administrators.invite(p);
    if (this.listing.current) {
      await this.listing.current.refresh();
    }
    this.setState({ showCreateDialog: false });
  };

  render() {
    return (
      <div style={{ padding: 30 }}>
        <h3>Administrators</h3>
        <Options>
          <Button onClick={() => this.setState({ showCreateDialog: true })}>
            Invite new administrator
          </Button>
        </Options>
        <Async fetchProps={api.administrators.list} ref={this.listing}>
          {users => (
            <List>
              {users.map(({ email, first_name, last_name, registered }) => (
                <User key={email}>
                  {first_name} {last_name} <span>{email}</span>{" "}
                  <span>
                    {registered ? (
                      <FaCheck color="green" />
                    ) : (
                      <FaTimes color="grey" />
                    )}
                  </span>
                </User>
              ))}
            </List>
          )}
        </Async>
        {this.state.showCreateDialog && (
          <CreateDialog
            onClose={() => this.setState({ showCreateDialog: false })}
            onSubmit={this.createUser}
          />
        )}
      </div>
    );
  }
}

export default Admins;
