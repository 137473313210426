// @flow
import { _get, _post, _put, _delete, _upload } from "./methods";
import tiles from "./tiles";
import type { Place } from "./types";

export default {
  list: (filter: any) => _get("/admin/places", filter),
  get: (place_id: number): Promise<Place> => {
    return _get(`/admin/places/${place_id}`);
  },
  update: ({ place_id, ...data }: any) => {
    return _put(`/admin/places/${place_id}`, data);
  },
  uploadProfilePicture: ({
    place_id,
    file
  }: {
    place_id: number,
    file: Blob
  }) => _upload(`/admin/places/${place_id}/photos/main`, file, "photo"),
  tiles,
  photos: {
    // updateComment: ({ place_id, photo_id, ...rest }) =>
    //   _put(`/admin/places/${place_id}/photos/${photo_id}/comment`, rest)
  },
  google: {
    list: (p: any) => _get("/admin/places/google", p)
  },
  curation: {
    add: ({ google_place_id, ...rest }: any) =>
      _post(`/admin/places/curation/${google_place_id}`, rest),
    remove: ({ google_place_id }: any) =>
      _delete(`/admin/places/curation/${google_place_id}`)
  }
};
