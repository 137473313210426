import React from "react";

const get = key => {
  const val = localStorage.getItem(key);
  if (val === undefined) {
    return val;
  }
  try {
    return JSON.parse(val);
  } catch (e) {
    return undefined;
  }
};

const set = (key, val) => {
  localStorage.setItem(key, JSON.stringify(val));
};

class Preference extends React.Component {
  constructor(props) {
    super(props);
    const val = get(props.name);
    this.state = {
      val: val !== undefined ? val : props.def
    };
  }

  change = val => {
    set(this.props.name, val);
    this.setState({ val });
  };

  render() {
    const { val } = this.state;
    return this.props.children(val, this.change);
  }
}

export default Preference;
