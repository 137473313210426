// @flow
import React from "react";
import { DateRangeInput } from "@blueprintjs/datetime";
import moment from "moment";
import { formatLocalDatetime } from "../../../services/api/tiles";

const parseLocalDateTime = (str: string): Date => {
  // Various browsers have different behaviour when parsing
  // ISO datetime strings without the timezone identifier.
  // Moment parses such strings correctly, so we use it
  // to obtain the local date object.
  return moment(str).toDate();
};

type P = {
  onChange: ([string, string]) => void,
  value: [string, string]
};

const LocalDateRangeInput = (props: P) => {
  const { onChange, value, ...rest } = props;

  return (
    <DateRangeInput
      timePrecision="minute"
      formatDate={date => date.toLocaleString()}
      parseDate={str => new Date(str)}
      {...rest}
      onChange={val => {
        onChange(
          val.map(dateOrNull => dateOrNull && formatLocalDatetime(dateOrNull))
        );
      }}
      value={[parseLocalDateTime(value[0]), parseLocalDateTime(value[1])]}
    />
  );
};

export default LocalDateRangeInput;
