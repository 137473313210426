// @flow

/**
 * Returns the list with the i-th and j-th elements swapped.
 */
export const swap = (xs: any[], i: number, j: number): any[] => {
  if (i === j) {
    return xs;
  }
  const ei = xs[i];
  const ej = xs[j];
  return xs.map((p, index) => {
    if (index === i) {
      return ej;
    }
    if (index === j) {
      return ei;
    }
    return p;
  });
};

/**
 * Returns the list with the i-th element removed.
 */
export const remove = (xs: any[], i: number): any[] => {
  return [...xs.slice(0, i), ...xs.slice(i + 1)];
};

/**
 * Returns the list with the i-th element replaced with newItem.
 */
export const replace = (xs: any[], i: number, newItem: any): any[] => {
  return xs.map((item, index) => (index === i ? newItem : item));
};
