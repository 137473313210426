import React from "react";
import { FaMapMarker } from "react-icons/fa";
import colors from "styles/colors";

const PlaceMarker = ({ curated, onClick }) => (
  <FaMapMarker
    onClick={onClick}
    size={15}
    color={curated ? colors.playgroundGreen : "grey"}
  />
);

export default PlaceMarker;
