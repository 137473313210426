import React, { Component } from "react";
import { CancellableDialog } from "components/dialogs";
import { FieldRow } from "components/forms";
import CategorySelect from "./CategorySelect";

class CurationForm extends Component {
  state = { name: this.props.defaultName, category_id: null };
  render() {
    return (
      <CancellableDialog
        isForm
        onClose={this.props.onCancel}
        onSubmit={() => this.props.onSubmit(this.state)}
      >
        <h5>Place curation (you will be able to edit details later)</h5>
        <FieldRow>
          <span>Place name:</span>
          <input
            required
            type="text"
            value={this.state.name}
            onChange={e => this.setState({ name: e.target.value })}
          />
        </FieldRow>
        <FieldRow>
          <span>Category</span>
          <CategorySelect
            value={this.state.category_id}
            categories={this.props.categories}
            onChange={category_id => this.setState({ category_id })}
          />
        </FieldRow>
      </CancellableDialog>
    );
  }
}

export default CurationForm;
