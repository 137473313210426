import React from "react";
import styled from "styled-components";
import api from "services/api";
import ListControls from "./ListControls";
import CurationForm from "./CurationForm";
import toasts from "services/toasts";
import Map from "./Map";
import Item from "./Item";
import Preference from "../../components/Preference";

const Container = styled.div`
  padding: 20px;
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
`;

const MapContainer = styled.div`
  height: 100%;
  width: 400px;
  display: ${props => (props.show ? "block" : "none")};
`;

const Pane = styled.div`
  flex: 1;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
`;

const ListingContainer = styled.div`
  flex: 1;
  overflow: auto;
`;
const INIT_POSITION = {
  london: { lat: 51.5073509, lng: -0.1277583 },
  paris: { lat: 48.856613, lng: 2.352222 },
  dubai: { lat: 25.276987, lng: 55.296249},
  madrid: { lat: 40.416799,  lng: -3.707783 },
  new_york: { lat: 40.722613, lng: -73.897492 },
  tel_aviv: { lat: 32.0852997, lng: 34.7818064 },
  lisbon: { lat: 38.707751, lng: -9.136592 },
  brussels: { lat: 50.846557, lng: 4.351697 },
  rio: { lat: -22.911014, lng: -43.209373 },
  beirut:  { lat: 33.8937913, lng: 35.5017767 },
  milan:  { lat: 45.4642, lng: 9.1900 },
  roma:   { lat: 41.9028, lng: 12.4964 },
  amsterdam:  { lat: 52.3666969, lng: 4.8945398 },
  athens:   { lat: 37.983810, lng: 23.727539 },
  barcelona: { lat: 41.3828939, lng: 2.1774322 },
  berlin: { lat: 52.5170365, lng: 13.3888599 },
  mykonos: { lat: 37.4513936, lng: 25.3923149 }
}; //london : todo, make this customizable ? possibly save in redux

class Places extends React.Component {
  constructor(props) {
    super(props);
    const pathname = this.props.location.pathname.split("/")[1];
    this.state = {
      currentPosition: INIT_POSITION[pathname],
      radius: 5000,
      tokens: [null],
      index: -1,
      places: [],
      keyword: "",
      curatedOnly: true,
      pendingCuration: null,
      selectedActivities: [],
      activities: null,
      categories: null,
      metadataLoaded: false,
      lat: INIT_POSITION[pathname].lat,
      lng: INIT_POSITION[pathname].lng,
      city: pathname
    };
  }

  loadPlaces = async (
    previous,
    pathname = this.props.location.pathname.split("/")[1]
  ) => {
    const {
      tokens,
      lat,
      lng,
      index,
      keyword,
      radius,
      curatedOnly,
      selectedActivities
    } = this.state;
    const nextIndex = previous ? index - 1 : index + 1;
    const page_token = tokens[nextIndex];

    const service = curatedOnly ? api.places : api.places.google;

    const { next_page_token, results } = await service.list({
      page_token,
      lat: this.state.city === pathname ? lat : INIT_POSITION[pathname].lat,
      lng: this.state.city === pathname ? lng : INIT_POSITION[pathname].lng,
      keyword,
      radius: radius ? radius : 16000,
      activities: JSON.stringify(selectedActivities)
    });
    if (nextIndex > tokens.length - 3) {
      tokens.push(next_page_token);
    }

    const londonFilter = results.filter(
      t =>
        t.geometry.location.lat <= 51.72 &&
        t.geometry.location.lat >= 51.25 &&
        t.geometry.location.lng >= -0.57 &&
        t.geometry.location.lng <= 0.37
    );
    const parisFilter = results.filter(
      t =>
        t.geometry.location.lat <= 48.9125 &&
        t.geometry.location.lat >= 48.8 &&
        t.geometry.location.lng >= 2.2125 &&
        t.geometry.location.lng <= 2.475
    );

    const dubaiFilter = results;

    const madridFilter = results;

    const telAviv = results;
    const beirutFilter = results;
    const milanFilter = results;
    const romaFilter = results;
    const athensFilter = results;
    const amsterdamFilter = results;
    const barcelonaFilter = results;
    const berlinFilter = results;
    const mykonosFilter = results;
    const lisbonFilter = results;
    const brusselsFilter = results;
    const rioFilter = results;

    const newYork = results;
    
    const cityFilters = {
      london: londonFilter,
      paris: parisFilter,
      dubai: dubaiFilter,
      tel_aviv: telAviv,
      lisbon: lisbonFilter,
      brussels: brusselsFilter,
      rio: rioFilter,
      beirut: beirutFilter,
      milan: milanFilter,
      roma: romaFilter,
      athens: athensFilter,
      amsterdam: amsterdamFilter,
      madrid: madridFilter,
      barcelona: barcelonaFilter,
      berlin: berlinFilter,
      mykonos: mykonosFilter,
      new_york: newYork,
    }

    this.setState({
      currentPosition: INIT_POSITION[pathname],
      tokens,
      index: nextIndex,
      places: cityFilters[pathname],
      lat: INIT_POSITION[pathname].lat,
      lng: INIT_POSITION[pathname].lng,
      city: pathname,
      resetToDefault: false
    });
  };

  componentDidMount = async () => {
    const pathname = this.props.location.pathname.split("/")[1];
    const activities = await api.activities.list();
    const categories = await api.categories.list();

    this.setState(
      {
        activities,
        categories,
        metadataLoaded: true
      },
      () => {
        this.loadPlaces(false, pathname);
      }
    );
  };
  componentWillReceiveProps(nextProps) {
    const pathname = nextProps.location.pathname.split("/")[1];
    this.setState(
      {
        resetToDefault: INIT_POSITION[pathname],
        lat: INIT_POSITION[pathname].lat,
        lng: INIT_POSITION[pathname].lng,
        tokens: [null],
        index: -1,
        radius: 8000
      },
      () => this.loadPlaces(false, pathname)
    );
  }

  onMapChange = e => {
    const { center, zoom, radius } = e;
    this.setState({ currentPosition: center, zoom, radius });
  };

  onSearch = keyword => {
    this.setState(
      { ...this.state.currentPosition, keyword, tokens: [null], index: -1 },
      () => this.loadPlaces(false)
    );
  };

  reloadMap = () => {
    window.dispatchEvent(new Event('resize'));
    this.setState(
      { ...this.state.currentPosition, tokens: [null], index: -1 },
      () => this.loadPlaces(false)
    );
  };

  onPlaceClick = i => {
    const coords = this.state.places[i].geometry.location;
    this.map.goToPlace(coords);
  };

  curate = async i => {
    const place = this.state.places[i];
    const { google_place_id, curated } = place;
    // avoid reloading UI. Not great but better for productivity.
    if (curated) {
      await api.places.curation.remove({ google_place_id });
      place.curated = false;
      place.id = undefined;
      this.forceUpdate();
    } else {
      this.setState({ pendingCuration: place });
    }
  };

  actuallyCurate = async ({ name, category_id }) => {
    const { google_place_id, geometry } = this.state.pendingCuration;
    const { id } = await api.places.curation.add({
      google_place_id,
      category_id,
      ...geometry.location,
      name
    });
    const place = this.state.places.find(
      p => p.google_place_id === google_place_id
    );
    place.curated = true;
    place.id = id;
    this.setState({ pendingCuration: null });
  };

  onCuratedChange = curatedOnly => {
    this.setState(
      { ...this.state.currentPosition, tokens: [null], index: -1, curatedOnly },
      () => this.loadPlaces(false)
    );
  };

  onPlaceUpdate = async (i, data) => {
    const place_id = this.state.places[i].id;
    await api.places.update({ place_id, ...data });
    toasts.success("Updated");
  };

  onPhotoDelete = async (i, photo_id) => {
    const place_id = this.state.places[i].id;
    await api.places.photos.delete({ place_id, photo_id });
  };

  onPhotoCommentChange = async (i, data) => {
    const place_id = this.state.places[i].id;
    await api.places.photos.updateComment({ place_id, ...data });
    toasts.success("Saved");
  };

  onActivitiesChange = async selectedActivities => {
    this.setState(
      {
        ...this.state.currentPosition,
        tokens: [null],
        index: -1,
        selectedActivities
      },
      () => this.loadPlaces(false)
    );
  };

  render() {
    const {
      places,
      tokens,
      index,
      curatedOnly,
      pendingCuration,
      selectedActivities,
      categories,
      activities,
      metadataLoaded
    } = this.state;

    return (
      <Preference name="showMap" def={true}>
        {(showMap, setMapOn) => (
          <Container>
            <MapContainer show={showMap}>
              <Map
                places={places}
                onMapChange={this.onMapChange}
                ref={map => (this.map = map)}
                onReload={this.reloadMap}
                initPosition={this.state.currentPosition}
                resetToDefault={this.state.resetToDefault}
              />
            </MapContainer>
            <Pane>
              {metadataLoaded && (
                <React.Fragment>
                  <label>
                    <input
                      type="checkbox"
                      checked={showMap}
                      onChange={e => setMapOn(e.target.checked)}
                    />{" "}
                    Show map
                  </label>
                  <ListControls
                    hasNext={!!tokens[index + 1]}
                    hasPrev={index > 0}
                    onNext={() => this.loadPlaces(false)}
                    onPrev={() => this.loadPlaces(true)}
                    onReload={this.reloadMap}
                    onSearch={this.onSearch}
                    curatedOnly={curatedOnly}
                    onCuratedChange={this.onCuratedChange}
                    activities={activities}
                    selectedActivities={selectedActivities}
                    onActivitiesChange={this.onActivitiesChange}
                  />
                  <ListingContainer>
                    {places.map(({ google_place_id, ...rest }, i) => (
                      <Item
                        key={google_place_id}
                        onClick={() => this.onPlaceClick(i)}
                        onCuration={() => this.curate(i)}
                        onPlaceUpdate={data => this.onPlaceUpdate(i, data)}
                        onPhotoDelete={id => this.onPhotoDelete(i, id)}
                        onPhotoCommentChange={p =>
                          this.onPhotoCommentChange(i, p)
                        }
                        activities={activities}
                        categories={categories}
                        {...rest}
                      />
                    ))}
                  </ListingContainer>
                  {pendingCuration && (
                    <CurationForm
                      onCancel={() => this.setState({ pendingCuration: null })}
                      categories={categories}
                      defaultName={pendingCuration.name}
                      onSubmit={this.actuallyCurate}
                    />
                  )}
                </React.Fragment>
              )}
            </Pane>
          </Container>
        )}
      </Preference>
    );
  }
}

export default Places;
