// @flow
import React from "react";
import type { Period } from "../../../services/api/types";
import List from "./List";
import { replace } from "../../../minus";
import moment from "moment";
import { formatLocalDatetime } from "../../../services/api/tiles";
import LocalDateRangeInput from "./LocalDateRangeInput";

type P = {
  value: Period[],
  onChange: (Period[]) => void
};

const Periods = ({ value, onChange }: P) => {
  const change = (i, newRange) => {
    const [start, end] = newRange;
    onChange(replace(value, i, { start, end }));
  };
  return (
    <List
      items={value}
      onChange={onChange}
      makeItem={() => ({
        start: formatLocalDatetime(
          moment()
            .startOf("day")
            .toDate()
        ),
        end: formatLocalDatetime(
          moment()
            .add(1, "day")
            .startOf("day")
            .toDate()
        )
      })}
      renderItem={(period, i) => (
        <LocalDateRangeInput
          onChange={val => {
            change(i, val);
          }}
          value={[period.start, period.end]}
          minDate={moment()
            .subtract(2, "years")
            .toDate()}
          maxDate={moment()
            .add(2, "years")
            .toDate()}
        />
      )}
    />
  );
};

export default Periods;
