import React, { Component } from "react";
import { CancellableDialog } from "components/dialogs";
import { FieldRow } from "components/forms";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class CreateDialog extends Component {
  state = { datetime: "", message: "" , place_id: null };
  render() {
    const { onClose, onSubmit } = this.props;
    const { datetime, message, place_id } = this.state;
    return (
      <CancellableDialog
        isForm
        onClose={onClose}
        onSubmit={() => onSubmit(this.state)}
      >
        <h5>Add push notification</h5>
        <FieldRow>
          <span>Send time</span>
          <DatePicker
            showTimeSelect
            onChange={data => {

            this.setState({ datetime: data })}}
            selected={datetime}
            dateFormat="dd MMMM Y HH:mm"
            timeIntervals={1} 
            timeFormat="HH:mm"
            required
          />
        </FieldRow>
        <FieldRow>
          <span>Message</span>
          <textarea
            type="text"
            required
            value={ message }
            onChange={e => this.setState({ message: e.target.value })}
          >
          </textarea>
        </FieldRow>
        <FieldRow>
          <span>Place ID</span>
          <input
            type="number"
            min="0"
            value={place_id || ""}
            onChange={e => {
              const place_id = e.target.value;
              if (!place_id || Number.isInteger(Number(place_id))) {
                this.setState({ place_id: place_id === "" ? null : Number(place_id) });
              }
            }}
          />
        </FieldRow>
      </CancellableDialog>
    );
  }
}

export default CreateDialog;
