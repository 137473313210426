// @flow
import React, { Component } from "react";
import Async from "../../components/Async";
import api from "../../services/api";
import styled from "styled-components";
import { Button, DeleteButton } from "../../components/buttons";
import CreateDialog from "./CreateDialog";
import List from "../../components/List";
import toasts from "../../services/toasts";

const PushNotification = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Options = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;

type P = {};
type S = {
  showCreateDialog: boolean
};

class PushNotifications extends Component<P, S> {
  state = {
    showCreateDialog: false
  };

  listing = React.createRef<Async>();

  createPushNotification = async (p: any) => {
    try {
      p.datetime = new Date(p.datetime);
      await api.push_notifications.create(p);
      if (this.listing.current) {
        await this.listing.current.refresh();
      }
      this.setState({ showCreateDialog: false });
    } catch (err) {
      const message = err.response.data.error_code ? err.response.data.message : err.toString();
      toasts.error(message);
    }
  };

  deletePushNotification = async (push_id: any) => {
    await api.push_notifications.delete({ push_id });
    if (this.listing.current) {
      await this.listing.current.refresh();
    }
  };

  render() {
    return (
      <div style={{ padding: 30 }}>
        <h3>Push notifications</h3>
        <Options>
          <Button onClick={() => this.setState({ showCreateDialog: true })}>
            Create push notification
          </Button>
        </Options>
        <Async fetchProps={api.push_notifications.list} ref={this.listing}>
          {push_notifications => (
            <List>
            {push_notifications.map(({ message, datetime, expired, id, place_id }) => (
              <PushNotification key={`row-${id}`}>
                {message}
                <span>{ datetime ? (new Date(datetime)).toLocaleString() : null }</span>
                <span>{place_id !== null ? `Place ID: ${place_id}` : 'No Place ID'}</span>
                <span>
                  <DeleteButton
                    confirm={`Delete push notification - ${message}`}
                    onClick={() => this.deletePushNotification(id)}
                  />
                </span>
              </PushNotification>
            ))}
            </List>
          )}
        </Async>
        {this.state.showCreateDialog && (
          <CreateDialog
            onClose={() => this.setState({ showCreateDialog: false })}
            onSubmit={this.createPushNotification}
          />
        )}
      </div>
    );
  }
}

export default PushNotifications;
