import React from "react";
import styled from "styled-components";
import { Button } from "components/buttons";
import colors from "styles/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Form = styled.form`
  width: 500px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 28px;
  color: ${colors.playgroundGreen};
  margin-bottom: 30px;
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 18px;
`;

class View extends React.Component {
  submit = e => {
    e.preventDefault();
    this.props.onSubmit(e);
  };
  render() {
    return (
      <Container>
        <Header>playground</Header>
        <Title>{this.props.title}</Title>
        <Form onSubmit={this.submit}>
          {this.props.children}
          <Button type="submit" style={{ marginTop: 8 }}>
            Submit
          </Button>
        </Form>
      </Container>
    );
  }
}

export default View;
