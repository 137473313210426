import React, { Component } from "react";
import { CancellableDialog } from "components/dialogs";
import { FieldRow } from "components/forms";

class CreateDialog extends Component {
  state = { first_name: "", last_name: "", email: "" };
  render() {
    const { onClose, onSubmit } = this.props;
    const { first_name, last_name, email } = this.state;
    return (
      <CancellableDialog
        isForm
        onClose={onClose}
        onSubmit={() => onSubmit(this.state)}
      >
        <h5>Invite administrator</h5>
        <FieldRow>
          <span>First Name</span>
          <input
            type="text"
            value={first_name}
            onChange={e => this.setState({ first_name: e.target.value })}
          />
        </FieldRow>
        <FieldRow>
          <span>Last Name</span>
          <input
            type="text"
            value={last_name}
            onChange={e => this.setState({ last_name: e.target.value })}
          />
        </FieldRow>
        <FieldRow>
          <span>Email</span>
          <input
            type="email"
            value={email}
            onChange={e => this.setState({ email: e.target.value })}
          />
        </FieldRow>
      </CancellableDialog>
    );
  }
}

export default CreateDialog;
