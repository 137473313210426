// @flow
import React from "react";
import List from "../List";
import TimeInput from "../../TimeInput";
import { replace } from "../../../../minus";

const Times = ({
  value,
  onChange
}: {
  value: string[],
  onChange: (string[]) => void
}) => {
  return (
    <List
      items={value}
      onChange={onChange}
      makeItem={() => "00:00"}
      renderItem={(time, i) => (
        <TimeInput
          value={time}
          onChange={val => {
            onChange(replace(value, i, val));
          }}
        />
      )}
    />
  );
};

export default Times;
