import React from "react";
import styled, { css } from "styled-components";
import { Flex } from "components/layout";
import { ColoredButton, DangerButton } from "components/buttons";
import colors from "styles/colors";
import CollapsibleSection from "../../components/CollapsibleSection";

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${({ banned }) =>
    banned &&
    css`
      color: ${colors.danger};
      text-decoration: line-through;
    `};
`;

const Details = styled.div`
  padding: 30px;
`;

const Contents = ({ banned, profile, onBan, onUnban, reasons }) => {
  const { age, gender, profile_picture_url } = profile;
  return (
    <Flex>
      <Details>
        <h5>Profile</h5>
        <p>
          {gender}, {age}
        </p>
        <img alt="profile" src={profile_picture_url} />
      </Details>
      <Details>
        <h5>Reported reasons: </h5>
        {reasons &&
          reasons.map(({ reason, count }) => (
            <div key={reason}>
              {reason} ({count} reports)
            </div>
          ))}
      </Details>
      {banned ? (
        <ColoredButton
          onClick={onUnban}
          style={{ alignSelf: "flex-start", margin: 30 }}
        >
          Unban User
        </ColoredButton>
      ) : (
        <DangerButton
          onClick={onBan}
          style={{ alignSelf: "flex-start", margin: 30 }}
        >
          Ban User
        </DangerButton>
      )}
    </Flex>
  );
};

class User extends React.PureComponent {
  state = { details: null };

  handleOpen = async () => {
    if (this.state.details) {
      return;
    }
    const details = await this.props.loadDetails();
    this.setState({ details });
  };

  render() {
    const {
      name,
      id,
      banned,
      profile,
      report_count,
      onBan,
      onUnban
    } = this.props;
    const { details } = this.state;
    return (
      <CollapsibleSection
        onOpen={this.handleOpen}
        title={
          <Header banned={banned}>
            <span>
              {name} ({id})
            </span>
            <strong>reports: {report_count}</strong>
          </Header>
        }
        content={
          <Contents
            reasons={details}
            banned={banned}
            profile={profile}
            onBan={onBan}
            onUnban={onUnban}
          />
        }
      />
    );
  }
}

export default User;
