// @flow
import React, { useState } from "react";
import { TextButton } from "../../../../components/buttons";
import type { ShowHideRule } from "../../../../services/api/types";
import FormDialog from "../../../../components/FormDialog";
import ShowHideTimeData from "./ShowHideTimeData";

type P = {
  value: ShowHideRule,
  onChange: ShowHideRule => any
};

const ShowHideInput = (props: P) => {
  const { value, onChange } = props;
  const [isOpen, setOpen] = useState(false);
  const periods = value.hide.length + value.show.length;

  return (
    <>
      <div>
        <span>{periods > 0 ? `${periods} values` : "No values"}</span>
        <TextButton onClick={() => setOpen(true)}>Edit</TextButton>
      </div>
      <FormDialog
        title="Show/Hide"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        closeButtonLabel="Hide"
      >
        <ShowHideTimeData time_data={value} onChange={onChange} />
      </FormDialog>
    </>
  );
};

export default ShowHideInput;
