// @flow
import React from "react";
import type { Showtime as ShowtimeType } from "../../../../services/api/types";
import { replace } from "../../../../minus";
import Showtime from "./Showtime";
import { Button } from "../../../../components/buttons";
import { spacing } from "../../../../components/layout";
import styled from "styled-components";
import moment from "moment";

const Container = styled.div``;

const Card = styled.div`
  background: white;
  padding: ${spacing};
  margin-bottom: ${spacing};
  border-radius: 4px;
`;

const Showtimes = ({
  value,
  onChange
}: {
  value: ShowtimeType[],
  onChange: (ShowtimeType[]) => void
}) => {
  // It should be impossible to have a dated showtime without dates.
  // Make it so that deleting the last date range from a showtime
  // results in removal of that showtime.
  const handleChange = showTimes => {
    onChange(showTimes.filter(x => x.dates.length > 0));
  };

  const makeItem = () => ({
    dates: [
      {
        start: moment()
          .startOf("day")
          .toDate()
          .toISOString(),
        end: moment()
          .startOf("day")
          .toDate()
          .toISOString()
      }
    ],
    values: []
  });

  const handleAdd = () => {
    handleChange(value.concat(makeItem()));
  };

  return (
    <Container>
      {value.map((showtime, i) => (
        <Card key={i}>
          <Showtime
            value={showtime}
            onChange={val => {
              handleChange(replace(value, i, val));
            }}
          />
        </Card>
      ))}
      <Button onClick={handleAdd}>Add a Group</Button>
    </Container>
  );
};

export default Showtimes;
