// @flow
import React, { useState } from "react";
import { FieldRow } from "../../components/forms";
import { Button, ColoredButton } from "../../components/buttons";
import ImageFileInput from "../../components/ImageFileInput";
import { FormButtonsContainer } from "../../components/layout";

type P = {
  onCancel: void => void,
  onSubmit: ({ name: string, file: {} }) => void,
  edit: boolean,
  activity: any,
  photoIsRequired: boolean
};

const ActivityForm = (props: P) => {
  const {
    onCancel,
    onSubmit,
    edit = false,
    activity,
    photoIsRequired = false
  } = props;
  const [name, setName] = useState(edit ? activity.name : "");

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({
      name: e.target.querySelector('[name="name"]').value,
      file:
        e.target.querySelector('[name="file"]') &&
        e.target.querySelector('[name="file"]').files[0]
          ? e.target.querySelector('[name="file"]').files[0]
          : null
      // london: e.target.querySelector('[name="london"]').value,
      // paris: e.target.querySelector('[name="paris"]').value
    });
  };

  // const setDefaultValue = name => {
  //   return edit ? activity[name] : "available";
  // };
  return (
    <form onSubmit={handleSubmit} style={{ padding: "20px 20px 0" }}>
      <FieldRow>
        <label>Name</label>
        <input
          name="name"
          required
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </FieldRow>
      <FieldRow>
        <label>Icon</label>
        <div>
          <ImageFileInput
            name="file"
            activity={activity}
            required={photoIsRequired}
          />
        </div>
      </FieldRow>
      {/* <FieldRow>
        <label>London</label>
        <select name="london" defaultValue={setDefaultValue("london")}>
          <option value="available">Available</option>
          <option value="hidden">Hidden</option>
          <option value="closed">Closed</option>
        </select>
      </FieldRow>
      <FieldRow>
        <label>Paris</label>
        <select name="paris" defaultValue={setDefaultValue("paris")}>
          <option value="available">Available</option>
          <option value="hidden">Hidden</option>
          <option value="closed">Closed</option>
        </select>
      </FieldRow> */}
      {!edit ? (
        <div
          style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}
        >
          <Button type="button" onClick={onCancel}>
            Cancel
          </Button>
          <ColoredButton type="submit">Create</ColoredButton>
        </div>
      ) : (
        <FormButtonsContainer>
          <ColoredButton
            // disabled={loading}
            type="submit"
          >
            Save
          </ColoredButton>
        </FormButtonsContainer>
      )}
    </form>
  );
};

export default ActivityForm;
