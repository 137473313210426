import React from "react";
import styled from "styled-components";
import { Flex } from "components/layout";
import { ColoredButton } from "components/buttons";
import CollapsibleSection from "../../components/CollapsibleSection";

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Details = styled.div`
  padding: 30px;
  display: flex;
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
`;

const ProfileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ProfileInfo = styled.p`
  margin-bottom: 20px;
  font-size: 18px;
`;

function getEmojiFromTag(tag) {
  switch (tag) {
    case 1:
      return "🌈";
    case 2:
      return "✈️";
    case 3:
      return "👶";
    case 4:
      return "🐶";
    case 5:
      return "💌";
    case 6:
      return "🏡";
    case 7:
      return "🧑‍🎓";
    case 8:
      return "✊🏾";
    case 9:
      return "💏";
    case 10:
      return "🏃";
    case 11:
      return "🧑‍🍳";
    default:
      return "";
  }
}

const Contents = ({ profile, onDelete }) => {
  const { age, gender, tags, occupation, instagram, profile_picture_url } = profile;

  const tagEmojis = tags.map(tag => getEmojiFromTag(tag)).join(" ");

  return (
    <Flex>
      <Details>
        <ProfileImage alt="profile" src={profile_picture_url} />
        <ProfileInfoWrapper>
          <ProfileInfo>
            <strong>Gender:</strong> {gender}
          </ProfileInfo>
          <ProfileInfo>
            <strong>Age:</strong> {age}
          </ProfileInfo>
          <ProfileInfo>
            <strong>Tags:</strong> {tagEmojis}
          </ProfileInfo>
          <ProfileInfo>
            <strong>Occupation:</strong> {occupation}
          </ProfileInfo>
          <ProfileInfo>
            <strong>Instagram/Twitter/Linkedin:</strong> {instagram}
          </ProfileInfo>
        </ProfileInfoWrapper>
      </Details>

      <ColoredButton onClick={onDelete} style={{ alignSelf: "flex-start", margin: 30 }}>
        Delete User
      </ColoredButton>
    </Flex>
  );
};

class User extends React.PureComponent {
  state = { details: null };

  handleOpen = async () => {
    if (this.state.details) {
      return;
    }
    const { age, gender, tags, occupation, instagram, profile_picture_url } = await this.props.loadDetails();
    this.setState({ details: { age, gender, tags, occupation, instagram, profile_picture_url } });
  };

  render() {
    const { name, id, banned, profile, onDelete } = this.props;
    const { details } = this.state;
    return (
      <CollapsibleSection
        onOpen={this.handleOpen}
        title={
          <Header banned={banned}>
            <span>
              {name} ({id})
            </span>
          </Header>
        }
        content={
          <Contents banned={banned} profile={details || profile} onDelete={onDelete} />
        }
      />
    );
  }
}

export default User;
