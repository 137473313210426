import React, { Component } from "react";
import { CancellableDialog } from "components/dialogs";
import { FieldRow } from "components/forms";

class CreateDialog extends Component {
  state = { code: "", invite_count: ""};
  render() {
    const { onClose, onSubmit } = this.props;
    const { code, invite_count } = this.state;
    return (
      <CancellableDialog
        isForm
        onClose={onClose}
        onSubmit={() => onSubmit(this.state)}
      >
        <h5>Invite first user</h5>
        <FieldRow>
          <span>Code</span>
          <input
            type="text"
            value={code}
            required
            onChange={e => this.setState({ code: e.target.value })}
          />
        </FieldRow>
        <FieldRow>
          <span>Invite count</span>
          <input
            type="number"
            min={0}
            value={invite_count}
            required
            onChange={e => this.setState({ invite_count: e.target.value })}
          />
        </FieldRow>

      </CancellableDialog>
    );
  }
}

export default CreateDialog;
