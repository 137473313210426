// @flow
import styled from "styled-components";

const List = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    padding: 10px 30px;
    border-bottom: 1px rgba(0, 0, 0, 0.05) solid;
  }
`;

export default List;
