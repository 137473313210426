// @flow
import React, { Component } from "react";
import Async from "../../components/Async";
import api from "../../services/api";
import styled from "styled-components";
import { borderColor, spacing } from "../../components/layout";
import { FaCheck, FaTimes } from "react-icons/fa";

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  border-collapse: collapse;
  & td {
    border: 1px solid ${borderColor};
    padding: calc(${spacing} / 2);
  }
`;

type P = {};
type S = {
  showCreateDialog: boolean
};

class UsersInfo extends Component<P, S> {
  listing = React.createRef<Async>();

  render() {
    return (
      <div style={{ padding: 30 }}>
        <h3>Users info</h3>
        <Async fetchProps={api.users_info.list} ref={this.listing}>
          {users => (
            <Table >
              <thead>
                <tr key="header">
                  <th>

                  </th>
                  <th>
                    Name
                  </th>
                  <th>
                    Age
                  </th>
                  <th>
                    Gender
                  </th>
                  <th>
                    Code
                  </th>
                  <th>
                    Invite phone
                  </th>
                  <th>
                    Phone
                  </th>
                  <th>
                    Send invite count
                  </th>
                  <th>
                    Invite by admin
                  </th>
                  <th>
                    Reports
                  </th>
                  <th>
                    Last action time
                  </th>
                </tr>
              </thead>
              <tbody>
              { users.res.map(
                ({ user_id, profile_pic, user_name, code, gender, phone, age, send_invite_count, invite_sender_phone, invite_by_admin, reports, last_action }) => (
                <tr key={`row-${user_id}`}>
                  <td> { profile_pic ? <img width="100px" src={ profile_pic } alt={ user_name }/> : null }</td>
                  <td>{ user_name }</td>
                  <td>{ age }</td>
                  <td>{ gender }</td>
                  <td>{ code }</td>
                  <td>{ invite_sender_phone }</td>
                  <td>{ phone }</td>
                  <td>{ send_invite_count }</td>
                  <td> {invite_by_admin ? (
                    <FaCheck color="green" />
                  ) : (
                    <FaTimes color="grey" />
                  )}</td>
                  <td>{ reports.map((report) => `${report.reason} (${report.count})`) }</td>
                  <td>{ last_action ? (new Date(last_action)).toLocaleString() : null }</td>
                </tr>
              )) }
              </tbody>
            </Table>
          )}
        </Async>
      </div>
    );
  }
}

export default UsersInfo;
