// @flow
import React from "react";
import styled from "styled-components";
import colors from "../styles/colors";
import type { Activity } from "../services/api/types";

const Container = styled.div`
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 2px ${({ active }) => (active ? colors.playgroundGreen : "grey")}
    solid;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

type P = {
  activity: Activity,
  active: boolean,
  onClick?: void => void
};

const ActivityIcon = (props: P) => {
  const { activity, active, onClick } = props;
  return (
    <Container active={active} onClick={onClick}>
      <img src={activity.icon_url} alt="" />
    </Container>
  );
};

export default ActivityIcon;
