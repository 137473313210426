import React from "react";
import { createStore, combineReducers } from "redux";
import { Provider as RProvider } from "react-redux";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./reducers/auth";
import { PersistGate } from "redux-persist/integration/react";

const rootReducer = combineReducers({
  auth
});

const persistConfig = {
  key: "playground-admin-root",
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const persistor = persistStore(store);

export default store;

export const Provider = ({ children }) => {
  return (
    <RProvider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </RProvider>
  );
};
