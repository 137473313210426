import React, { Component } from "react";
import { Provider } from "store";
import styled from "styled-components";
import LeftNav from "./LeftNav";
import { Router } from "react-router";
import history from "services/history";
import Routes from "./Routes";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  color: #16161d;
  * {
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
  }
`;

const Content = styled.div`
  flex: 1;
  height: 100%;
  max-height: 100%;
  overflow: auto;
`;

class App extends Component {
  render() {
    return (
      <Provider>
        <Router history={history}>
          <Container>
            <LeftNav />
            <Content>
              <Routes />
            </Content>
          </Container>
        </Router>
      </Provider>
    );
  }
}

export default App;
