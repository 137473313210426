// @flow
import React from "react";
import PlaceForm from "./PlaceForm";
import ProfilePicture from "./ProfilePicture";
import TilesEditor from "./TilesEditor";
import { Tabs, Tab } from "@blueprintjs/core";
import type { Activity, Category, Place } from "../../services/api/types";

type P = {
  activities: Activity[],
  categories: Category[],
  details: Place | null,
  onPlaceUpdate: void => void,
  place_id: number,
  setProfilePicture: Blob => void
};

const PlaceDetails = (props: P) => {
  const {
    activities,
    categories,
    details,
    onPlaceUpdate,
    place_id,
    setProfilePicture
  } = props;
  if (!details) {
    return <p>Loading...</p>;
  }

  return (
    <Tabs>
      <Tab
        id="avatar"
        title="Profile Picture"
        panel={
          <section>
            <h5>Profile Picture</h5>
            <ProfilePicture
              key={details.main_photo ? details.main_photo.photo_url : "none"}
              picture={details.main_photo}
              onSubmit={setProfilePicture}
            />
          </section>
        }
      />
      <Tab
        id="details"
        title="Place Details"
        panel={
          <section>
            <h5>Place Details</h5>
            <PlaceForm
              categories={categories}
              activities={activities}
              initValue={details}
              onSubmit={onPlaceUpdate}
            />
          </section>
        }
      />
      <Tab
        id="photos"
        title="Photo Tiles"
        panel={
          <section>
            <h5>Photo Tiles</h5>
            <TilesEditor place_id={place_id} />
          </section>
        }
      />
    </Tabs>
  );
};

export default PlaceDetails;
