import React, { Component } from "react";
import Async from "components/Async";
import api from "services/api";
import PendingUser from "./PendingUser";
import Page from "../../components/Page";

const Listing = ({ users, onApprove, loadDetails }) => {
  return (
    <div>
      {users.map(u => (
        <PendingUser
          {...u}
          key={u.id}
          onApprove={() => onApprove(u.id)}
          loadDetails={() => loadDetails({ user_id: u.id })}
        />
      ))}
    </div>
  );
};

class WaitingList extends Component {
  state = {};

  onApprove = async user_id => {
    await api.waiting_list.approve({ user_id });
    this.listing.refresh();
  };


  render() {
    return (
      <Page title="Waiting List">
        <Async
          fetchProps={api.waiting_list.pending.list}
          ref={a => (this.listing = a)}
        >
          {users => (
            <Listing
              users={users}
              onApprove={this.onApprove}
            />
          )}
        </Async>
      </Page>
    );
  }
}

export default WaitingList;
