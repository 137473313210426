// @flow
import tilesAPI from "./tiles";
import photosAPI from "./photos";
import type { Tile } from "./types";

const resourcesDiff = <T: { id?: number }>(
  currentList: T[],
  changedList: T[]
): { created: T[], updated: T[], deleted: T[] } => {
  const ids = new Set(changedList.map(x => x.id).filter(Boolean));
  return {
    // Created are the resources that don't have an ID yet.
    created: changedList.filter(x => !x.id),

    // Updated are the resources that are present in the current and the changed lists.
    updated: changedList.filter(x => x.id),

    // Deleted are the resources that are in the changed list, but not in the current list.
    deleted: currentList.filter(x => !ids.has(x.id))
  };
};

/**
 * Takes two complete tile states, before and after,
 * and figures out a set of GET/PUT/DELETE requests
 * to synchronize them with the backend.
 */
export async function syncPlaceTiles(
  place_id: number,
  tilesBefore: Tile[],
  tilesAfter: Tile[]
) {
  const { updated, created, deleted } = resourcesDiff(tilesBefore, tilesAfter);

  const getTileBefore = tileAfter =>
    tilesBefore.find(t => t.id === tileAfter.id);

  await Promise.all([
    ...created.map(tile => createTile(place_id, tile)),
    ...updated.map(tile => {
      const tileBefore = getTileBefore(tile);
      if (!tileBefore) {
        throw new Error("couldn't find tileBefore");
      }
      return updateTile(place_id, tileBefore, tile);
    }),
    ...deleted.map(tile => deleteTile(place_id, tile))
  ]);
}

async function createTile(place_id: number, tile: Tile) {
  const result = await tilesAPI.add(place_id, tile);
  const tile_id = result.place_tile.id;
  tile.photos.forEach(x => {
    x.tile_id = tile_id;
  });

  await Promise.all(
    tile.photos.map(photo => tilesAPI.photos.add(place_id, photo))
  );
}

async function updateTile(place_id: number, tileBefore: Tile, tileAfter: Tile) {
  const { created, updated, deleted } = resourcesDiff(
    tileBefore.photos,
    tileAfter.photos
  );

  await Promise.all([
    tilesAPI.update(place_id, tileAfter),
    ...created.map(photo => tilesAPI.photos.add(place_id, photo)),
    ...updated.map(photo => photosAPI.update(place_id, photo)),
    ...deleted.map(photo => photosAPI.del(place_id, photo))
  ]);
}

async function deleteTile(place_id: number, tile: Tile) {
  const tile_id = tile.id;
  if (!tile_id) {
    throw new Error("deleteTile: missing tile ID");
  }
  await Promise.all(tile.photos.map(photo => photosAPI.del(place_id, photo)));
  await tilesAPI.delete(place_id, tile_id);
}
