import React, { Component } from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import { getRadius } from "./util";
import mapStyle from "./mapStyle";
import { Button } from "components/buttons";
import { FaSyncAlt } from "react-icons/fa";
import PlaceMarker from "./PlaceMarker";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const ReloadButton = styled(Button)`
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 3;
`;

class Map extends Component {
  state = {
    currentPosition: this.props.initPosition,
    zoom: 12
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.resetToDefault) {
      this.setState({ currentPosition: nextProps.resetToDefault, zoom: 12 });
    }
  }

  onMapChange = e => {
    const { center, zoom } = e;
    const radius = getRadius(e);
    this.setState({ currentPosition: center, zoom });
    this.props.onMapChange({ center, zoom, radius });
  };

  goToPlace = coords => {
    this.setState({
      currentPosition: coords,
      zoom: 18
    });
  };

  render() {
    const { places, onReload } = this.props;
    const { zoom, currentPosition } = this.state;
    return (
      <Container>
        <ReloadButton
          onClick={e => {
            e.stopPropagation();
            onReload(e);
          }}
        >
          <span>Reload</span> <FaSyncAlt style={{ marginLeft: 8 }} />
        </ReloadButton>
        <GoogleMapReact
          bootstrapURLKeys={{ key: API_KEY }}
          center={[currentPosition.lat, currentPosition.lng]}
          zoom={zoom}
          onChange={this.onMapChange}
          options={() => ({ styles: mapStyle })}
        >
          {places.map(({ geometry, google_place_id, curated }) => (
            <PlaceMarker
              lat={geometry.location.lat}
              lng={geometry.location.lng}
              key={google_place_id}
              curated={curated}
              onClick={() => this.goToPlace(geometry.location)}
            />
          ))}
        </GoogleMapReact>
      </Container>
    );
  }
}

export default Map;
