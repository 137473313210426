// @flow
import React from "react";
import type { ShowHideRule } from "../../../../services/api/types";
import Periods from "../Periods";

type P = {
  time_data: ShowHideRule,
  onChange: ShowHideRule => void
};

const ShowHideTimeData = (props: P) => {
  const { time_data, onChange } = props;
  const update = field => (value: any) => {
    onChange({ ...time_data, [field]: value });
  };
  return (
    <div>
      <div>
        <label>Show</label>
        <Periods value={time_data.show} onChange={update("show")} />
      </div>
      <div>
        <label>Hide</label>
        <Periods value={time_data.hide} onChange={update("hide")} />
      </div>
    </div>
  );
};

export default ShowHideTimeData;
