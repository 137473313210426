import React, { Component } from "react";
import { CancellableDialog } from "components/dialogs";
import { FieldRow } from "components/forms";
import "react-datepicker/dist/react-datepicker.css";

class SetAllCount extends Component {
  state = { invite_count: "" };
  render() {
    const { onClose, onSubmit } = this.props;

    return (
      <CancellableDialog
        isForm
        onClose={onClose}
        onSubmit={() => onSubmit(this.state)}
      >
        <h5>Set all invite count</h5>

        <FieldRow>
          <span>Invite count</span>
          <input
            type="number"
            min={0}
            value={ this.state.invite_count }
            required
            onChange={e => this.setState({ invite_count: e.target.value })}
          />
        </FieldRow>
      </CancellableDialog>
    );
  }
}

export default SetAllCount;
