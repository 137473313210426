// @flow
import React, { Component } from "react";
import Async from "../../components/Async";
import api from "../../services/api";
import styled from "styled-components";
import { Button, DeleteButton, EditButton } from "../../components/buttons";
import CreateDialog from "./CreateDialog";
import List from "../../components/List";
import toasts from "../../services/toasts";
import EditDialog from "./EditDialog";

const Item = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Options = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;

type P = {};
type S = {
  showCreateDialog: boolean
};

class AccessCodes extends Component<P, S> {
  state = {
    showCreateDialog: false,
    showEditItem: false,
    editableItem: null
  };

  listing = React.createRef<Async>();

  createItem = async (p: any) => {
    try {
      await api.access_codes.create(p);
      if (this.listing.current) {
        await this.listing.current.refresh();
      }
      this.setState({ showCreateDialog: false });
    } catch (err) {
      const message = err.response.data.error_code ? err.response.data.message : err.toString();
      toasts.error(message);
    }
  };

  editItem = async (p: any) => {
    try {
      await api.access_codes.update(p);
      if (this.listing.current) {
        await this.listing.current.refresh();
      }
      this.setState({ showEditDialog: false });
    } catch (err) {
      const message = err.response.data.error_code ? err.response.data.message : err.toString();
      toasts.error(message);
    }
  };

  deleteItem = async (code_id: any) => {
    await api.access_codes.delete({ code_id });
    if (this.listing.current) {
      await this.listing.current.refresh();
    }
  };

  showEditWindow = async (item_id: any) => {
    const data = await api.access_codes.list();
    const itemData = data.find(item => item.id === item_id);

    if (!itemData) {
      toasts.error('Access code not found in system');
    }

    this.setState({ showEditDialog: true, editableItem: itemData });
  };

  render() {

    return (
      <div style={{ padding: 30 }}>
        <h3>Access codes</h3>
        <Options>
          <Button onClick={() => this.setState({ showCreateDialog: true })}>
            Create new access code
          </Button>
        </Options>
        <Async fetchProps={api.access_codes.list} ref={this.listing}>
          {codes => (
            <List>
              {codes.map(({ code, invite_count, id }) => (
                <Item key={ code }>
                  { code }
                  <span>{ invite_count }</span>
                  <span>
                    <EditButton
                      onClick={() => this.showEditWindow(id)}
                    />
                    <DeleteButton
                      confirm={`Delete access code?`}
                      onClick={() => this.deleteItem(id)}
                    />
                  </span>
                </Item>
              ))}
            </List>
          )}
        </Async>
        { this.state.showCreateDialog && (
          <CreateDialog
            onClose={() => this.setState({ showCreateDialog: false })}
            onSubmit={this.createItem}
          />
        )}
        { this.state.showEditDialog && (
          <EditDialog
            itemData={this.state.editableItem}
            onClose={() => this.setState({ showEditDialog: false })}
            onSubmit={this.editItem}
           />
        )}
      </div>
    );
  }
}

export default AccessCodes;
