import React from "react";
import styled from "styled-components";
import { FaArrowLeft, FaArrowRight, FaSearch } from "react-icons/fa";
import { Button } from "components/buttons";
import Activities from "../../components/Activities";

const Container = styled.div`
  & > * {
    margin-bottom: 20px;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  flex-shrink: 0;
`;

class ListControls extends React.Component {
  state = {
    keyword: this.props.initKeyword || ""
  };
  render() {
    const {
      hasPrev,
      hasNext,
      onPrev,
      onNext,
      onSearch,
      curatedOnly,
      onCuratedChange,
      onActivitiesChange,
      activities,
      selectedActivities
    } = this.props;
    return (
      <Container>
        <Line>
          <Line>
            <label>
              Restrict to curated places
              <input
                type="checkbox"
                checked={curatedOnly}
                onChange={e => onCuratedChange(e.target.checked)}
              />
            </label>
          </Line>
          <Line style={{ justifyContent: "flex-end" }}>
            <span>keyword: </span>
            <input
              type="text"
              value={this.state.keyword}
              onChange={e => this.setState({ keyword: e.target.value })}
            />
            <Button onClick={() => onSearch(this.state.keyword)}>
              <span>Search</span> <FaSearch style={{ marginLeft: 8 }} />
            </Button>
          </Line>
        </Line>
        {curatedOnly && (
          <Activities
            activities={activities}
            value={selectedActivities}
            onChange={onActivitiesChange}
          />
        )}
        <Line>
          <Button onClick={onPrev} disabled={!hasPrev}>
            <FaArrowLeft />
          </Button>
          <Button onClick={onNext} disabled={!hasNext}>
            <FaArrowRight />
          </Button>
        </Line>
      </Container>
    );
  }
}

export default ListControls;
