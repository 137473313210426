// @flow
import React, { useState } from "react";
import { TextButton } from "../../../../components/buttons";
import FormDialog from "../../../../components/FormDialog";
import ShowtimesTimeData from "./ShowtimesTimeData";
import type { Showtimes } from "../../../../services/api/types";

type P = {
  value: Showtimes,
  onChange: Showtimes => any
};

const ShowtimesInput = (props: P) => {
  const { value, onChange } = props;
  const [isOpen, setOpen] = useState(false);
  const n = value.default_showtimes.length;
  const m = value.showtimes.length;

  return (
    <>
      <div>
        <span>
          {!n && !m ? "No values" : `${n} default ${m} date-specific`}
        </span>
        <TextButton onClick={() => setOpen(true)}>Edit</TextButton>
      </div>
      <FormDialog
        title="Showtimes"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        closeButtonLabel="Hide"
      >
        <ShowtimesTimeData time_data={value} onChange={onChange} />
      </FormDialog>
    </>
  );
};

export default ShowtimesInput;
